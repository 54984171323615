import React from 'react';
import i18next from 'i18next';
import { NavLink, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Body from '../../../templates/Body/Body';
import BodyExercise from '../../BodyExercise/BodyExercise';
import ExerciseIcons from '../../BodyExercise/ExerciseIcons';
import Loading from '../../../components/Loading/Loading';
import { BASE_URL } from '../../../utils/globals';
import { uploadImage } from '../../../api/upload';
import { updateStudentSheetAnswer } from '../../../api/student-sheets';
import { getInteractiveStudentSheet } from '../../../utils/ssUtils';
import { DescriptionTranslations, getExerciseDescription } from '../../../utils/ssUtils';

import icon from '../../../assets/images/student-sheets/uploadActivity.svg';
import '../ExC2.css';
import './ExC21.css';

class ExC21Class extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ex: null,
            loading: true,
            imgData: null,
            image: null,
            uploadImage: true,
            viewMode: false
        };
        this.onImageChange = this.onImageChange.bind(this);
        this.onImageRemove = this.onImageRemove.bind(this);
    }

    componentDidMount = async () => {
        localStorage.setItem('lastPage', '/exC21');
        const ssId = this.props.match.params.id;
        const user = JSON.parse(localStorage.getItem('user'));

        let answer, exercise;
        try {
            const response = await getInteractiveStudentSheet(ssId, user.id);
            exercise = response.exercise;
            answer = response.answer;
        } catch (error) {
            console.error('An error occurred: ', error);
            this.setState({ error: true });
            return;
        }

        const disableText = localStorage.getItem('disable');
        this.updateExercise(answer, disableText);

        this.setState({
            loading: false,
            ex: exercise,
            answerId: answer?.id
        });
    }

    updateExercise = (answer, disabled) => {
        const ex = answer.ExerciseC2_1_NewPoster;
        const image = ex?.length > 0 ? `${BASE_URL}${ex[0]?.url}` : null;

        if (disabled) {
            this.setState({
                image: image,
                viewMode: true
            });
        } else {
            this.setState({
                image: image,
                uploadImage: image ? false : true
            });

            localStorage.setItem('code', answer?.Code);
        }
    }

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            const img = event.target.files[0];

            if (event.target.files[0].size > 2000000) {
                alert(this.props.t('StudentSheetsContent.FileBig'))
            } else if (event.target.files[0].type !== "image/png" && event.target.files[0].type !== "image/jpeg") {
                alert(this.props.t('StudentSheetsContent.FileImage'))
            } else {
                this.setState({
                    imgData: img,
                    image: URL.createObjectURL(img),
                    uploadImage: false
                });

                event.target.value = '';
            }
        }

    };

    onImageRemove = event => {
        if (!event.target.files) {
            this.setState({
                imgData: null,
                image: null,
                uploadImage: true
            });
        }
    };

    submitHandler = async (e) => {
        e.preventDefault();

        if (!this.state.imgData) {
            alert('Image not selected!');
            return;
        }

        this.setState({ loading: true });

        let img;
        try {
            img = await uploadImage(this.state.imgData);
        } catch (error) {
            console.error('An error occurred: ', error);
            this.setState({ error: true });
            return;
        }

        const answerExercise = {
            ExerciseC2_1_NewPoster: img[0].id
        };

        try {
            await updateStudentSheetAnswer(this.state.answerId, answerExercise);
        } catch (error) {
            console.error('An error occurred: ', error);
            this.setState({ error: true });
            return;
        }

        this.setState({ loading: false });
    };

    resetExercise = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        const answerExercise = {
            ExerciseC2_1_NewPoster: []
        };

        try {
            await updateStudentSheetAnswer(this.state.answerId, answerExercise);
        } catch (error) {
            console.error('An error occurred: ', error);
            this.setState({ error: true });
            return;
        }

        this.setState({
            imgData: null,
            image: null,
            uploadImage: true,
            loading: false
        });
    }

    render() {
        const { t } = this.props;

        const checkToken = localStorage.getItem('token');
        if (!checkToken) { return <Redirect to="/noAccount" />; }

        if (this.state.loading) {
            return (
                <Body
                    bodyClass="content"
                    title="C2.1 Exercise | CinEd"
                >
                    <Loading />
                </Body>
            );
        }

        const exercise = this.state.ex;
        const description = getExerciseDescription(this.state.ex, 'C2_1', i18next.language, DescriptionTranslations);

        return (
            <BodyExercise
                selectedOption='exC21'
                studentSheetId={this.props.match.params.id}
                movieTitle={exercise?.MovieTitle}
                pageTitle={`C2.1 Exercise | ${exercise?.MovieTitle} | CinEd`}
                viewMode={this.state.viewMode}
                interactiveEx={true}
                prevLink={`/exC11/${this.props.match.params.id}`}
                nextLink={`/exD/${this.props.match.params.id}`}
            >
                <ExerciseIcons
                    studentSheetId={this.props.match.params.id}
                    exIcon={icon}
                    exTitle='StudentSheetsLeftMenu.ChoosingCreatingImages'
                    exDescription={description}
                    viewMode={this.state.viewMode}
                    resetExercise={this.resetExercise}
                    submitHandler={this.submitHandler}
                    activeReset
                    activeSubmit
                />

                <div className="exerciseContainer exC21">
                    <img
                        className="imageExerciseEx21"
                        src={`${BASE_URL}${exercise.C2_1.OriginalPoster.url}`}
                        alt="Original Poster"
                    />

                    <div className="uploadFileBox exC21">
                        <img
                            className="imageUploadedEx21"
                            src={this.state.image}
                            alt="Uploaded Poster"
                        />

                        {this.state.uploadImage && !this.state.viewMode ? <div>
                            <input
                                id="NewPoster"
                                className="inputfile"
                                type="file"
                                accept="image/png, image/jpeg"
                                name="files"
                                onChange={this.onImageChange}
                            />
                            <label htmlFor="NewPoster" className="imgButton">
                                {t('StudentSheetsContent.Poster')}
                            </label>
                        </div> : <></>}
                        
                        {this.state.uploadImage || this.state.viewMode ? <></> : <div className="changeContainer">
                            <button className="imgButton btnMaxWidth" onClick={this.onImageRemove}>
                                {t('StudentSheetsContent.ChangeRemove')}
                            </button>
                        </div>}
                    </div>
                </div>

                <div className="exerciseButtonsGroup21">
                    <div className="buttonsGroupWrapper">
                        <div>
                            <NavLink to={`/exC21/${this.props.match.params.id}`}>
                                <button className="exerciseButtonSelected">1</button>
                            </NavLink>
                        </div>
                        <div>
                            <NavLink to={`/exC22/${this.props.match.params.id}`}>
                                <button className="exerciseButtonNotSelected">2</button>
                            </NavLink>
                        </div>
                        <div>
                            <NavLink to={`/exC23/${this.props.match.params.id}`}>
                                <button className="exerciseButtonNotSelected">3</button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </BodyExercise>
        )
    }

}

export default withTranslation(['translation'])(ExC21Class);