import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MinimumRequirementsModal from './MinimumRequirements';
import Popup from './Popup';
import { getPlayer, lang } from '../../utils/utils';
import { getOS } from '../../utils/utils';
import CinedLogo from '../../assets/images/logo.svg';
import {ReactComponent as WindowsIcon} from '../../assets/images/windows.svg'
import {ReactComponent as AppleIcon} from '../../assets/images/apple.svg'

export default function WelcomePopup(props) {
    const { i18n, t } = useTranslation();
    const { support, player } = getPlayer();
    const [ isMRmodalOpen, setIsMRmodalOpen ] = useState(false) // MR means Minimum Requirements

    const downloadPlayer = () => {
        window.open(player);
    };


    return (
        <Popup {...props} isMRmodalOpen={isMRmodalOpen} setIsMRmodalOpen={setIsMRmodalOpen}>
            {isMRmodalOpen ? (
                <MinimumRequirementsModal />
            ) : (
                <div>
                    <div className="centeredContainer">
                        <img alt="CinEd Logo" src={CinedLogo} className="logo" />
                    </div>

                    <h2 
                        className="modalTitleTD h3Text"
                        lang={lang[i18n.language]}
                    >
                        {t('Player.Welcome')}
                    </h2>

                    <div className="centeredContainer column">
                        <div className='descriptionContainer'>
                            <div className="normalText">
                                {t('Player.WelcomeDesc1')}
                            </div>

                            {support ? <p className="normalText">
                                {t('Player.WelcomeDesc2')}
                            </p> : <></>}
                        </div>

                        {support ? <div
                            className="normalText downloadAnchor playerLinkContainer"
                        >
                            <div className='playerLink' onClick={downloadPlayer}>
                                <span>{t('NavBar.VideoPlayer')}</span> {getOS() === 'Mac OS' ? (<AppleIcon />) : (<WindowsIcon />)}
                            </div>
                            <div className='playerLinkFooter'>
                                <p>{t('Player.AlsoAvailable')} {getOS() === 'Mac OS' ? (<WindowsIcon />) : (<AppleIcon />)}</p>
                            </div>
                            <div className='PopupContainer'>
                                <a onClick={() => setIsMRmodalOpen(true)}>{t('Player.MinimumRequirementsButton')}</a>
                            </div>
                        </div> : <></>}
                    </div>
                </div>
            )}
        </Popup>
    );
}
