import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import Anim from '../Anim/Anim';
import Popup from './Popup';
import { IconTypes, lang } from '../../utils/utils';

export default function AccessPopup(props) {
    const { name, migrationToken } = props;
    const { i18n, t } = useTranslation();

    return (
        <Popup maxWidth="md" {...props}>
            <Anim iconType={IconTypes.WARNING} />

            <h2 
                className="modalTitleTD h3Text"
                lang={lang[i18n.language]}
            >
                {t('Player.Greetings')} {name}
            </h2>

            <div className="centeredContainer column">
                <div className="normalText">
                    {t('Player.Part1')}
                </div>

                <div className="normalText">
                    {t('Player.Part2')}
                </div>

                <NavLink to={`/migration/${migrationToken}/teacher`}>
                    <button className="modalButton warningBtn normalText">
                        {t('Player.Closing')}
                    </button>
                </NavLink>
            </div>
        </Popup>
    );
}
