import React from 'react';

export default function HomeIcon({ label, optionName, hoveredOption, setHoveredOption, fillColor, Icon }) {
    return (
        <div
            className="desktop-icon"
            onMouseEnter={() => setHoveredOption(optionName)}
            onMouseLeave={() => setHoveredOption('')}
        >
            <div className="home-icon-border">
                <div className={optionName === "collection" ? "home-icon-wrapper collection" : "home-icon-wrapper"}
                    style={{ backgroundColor: hoveredOption === optionName ? fillColor : 'rgba(1,2,2, 0.8)' }}
                >
                    <Icon fill={hoveredOption === optionName ? 'rgba(1,2,2, 0.8)' : fillColor} />
                </div>
            </div>

            <h4 className="home-icon-label">
                {label}
            </h4>
        </div>
    );
}