import React from 'react';
import i18next from 'i18next';
import ReactMarkdown from 'react-markdown';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Body from '../../templates/Body/Body';
import Loading from '../../components/Loading/Loading';
import PageHeader from '../../components/PageHeader/PageHeader';
import { BASE_URL } from '../../utils/globals';
import { getMovieImageURL, getTranslation, NewsEventsOptions, Translations } from '../../utils/utils';
import { getEventsNews } from '../../api/events-and-news';
import './NewsEvents.css';

const Prefix = 'news-events-translations.';
const DefaultTranslation = Prefix + 'english';

class NewsEventsClass extends React.Component {

    constructor() {
        super();

        this.state = {
            news: [],
            loading: true,
            selectedOption: 'all'
        };

        this.getSelectedNews = this.getSelectedNews.bind(this);
        this.getTranslatedType = this.getTranslatedType.bind(this);
        this.getTranslatedTitle = this.getTranslatedTitle.bind(this);
    }

    async componentDidMount() {
        let newList;
        try {
            newList = await getEventsNews();
        } catch (error) {
            console.error('An error occurred: ', error);
            return;
        }

        this.setState({
            loading: false,
            news: newList
        });
    }

    selectOption(option) {
        this.setState({ selectedOption: option });
    }

    getTranslatedTitle(content) {
        const language = Prefix + Translations[i18next.language];
        const translation = content?.Translations.find(x => x.__component === language);

        if (translation && translation.Title) {
            return translation.Title;
        } else return content.Title;
    }

    getTranslatedType(type) {
        const { t } = this.props;
        let translatedType = type;

        switch (type.toLowerCase()) {
            case 'news':
                translatedType = t('NewsEvents.ButtonNews');
                break;
            case 'event':
                translatedType = t('NewsEvents.ButtonEvents');
                break;
            default:
                console.log('Type does not exist...', type);
        }

        return translatedType;
    }

    getSelectedNews() {
        let selectedNews = [];
        const newsList = this.state.news;
        const onlyNews = [];
        const onlyEvents = [];

        for (let i = 0; i < newsList.length; i++) {
            if (newsList[i].Type === 'News') {
                onlyNews.push(newsList[i]);
            } else {
                onlyEvents.push(newsList[i]);
            }
        }

        switch (this.state.selectedOption) {
            case 'all':
                selectedNews = newsList;
                break;
            case 'news':
                selectedNews = onlyNews;
                break;
            case 'events':
                selectedNews = onlyEvents;
                break;
            default:
                console.error('NewsEvents:: Selected option does not exist!');
                return;
        }

        return selectedNews;
    }

    render() {
        const { t } = this.props;
        const { selectedOption, loading, news } = this.state;

        if (loading) {
            return (
                <Body
                    bodyClass="content headerContainer"
                    title={`${t('NavBar.EventsNews')} | CinEd`}
                >
                    <Loading />
                </Body>
            );
        }

        const headerNews = news[0];
        const headerTranslation = getTranslation(headerNews, i18next.language, Prefix, DefaultTranslation);

        const newsList = this.getSelectedNews();

        return (
            <Body
                bodyClass="content headerContainer"
                title={`${t('NavBar.EventsNews')} | CinEd`}
            >
                <div className="container">
                    <PageHeader
                        isMovie={false}
                        imgSrc={`${BASE_URL}${getMovieImageURL(headerNews, 'HighlightImage')}`}
                        title={headerTranslation?.Title ? headerTranslation.Title : headerNews?.Title}
                        body={headerTranslation ? <ReactMarkdown>{headerTranslation.Body}</ReactMarkdown> : <></>}
                        link={`/details/${headerNews?.id}`}
                        anchorLink="#news-section"
                    />
                </div>

                <div className="container newsContainer">
                    <div className="buttonsWrapperNE" id="news-section">
                        {Object.keys(NewsEventsOptions).map((opt, idx) =>
                            <button
                                key={idx}
                                className={`buttonNE bigText ${selectedOption === opt ? "selected" : ""}`}
                                onClick={() => this.selectOption(opt)}
                            >
                                {t(NewsEventsOptions[opt])}
                            </button>
                        )}
                    </div>

                    <div className="newEventStructure">
                        {newsList?.map((content) =>
                            <div className="newsEventsWrapper" key={content.id}>
                                <div className="imageContainerNE">
                                    <Link to={`/details/${content.id}`}>
                                        <img
                                            className="imageNE"
                                            src={`${BASE_URL}${getMovieImageURL(content, 'HighlightImage')}`}
                                            alt="News/Event Item"
                                        />
                                    </Link>

                                </div>

                                <div className="newsEventsWrapperInfo">
                                    <p className="typeNE detailsText">
                                        {this.getTranslatedType(content.Type)}
                                    </p>

                                    <p className="titleNE normalText">
                                        {this.getTranslatedTitle(content)}
                                    </p>

                                    <p className="dateNE">
                                        {content.Date}
                                    </p>

                                    <Link className="readMore detailsText" to={`/details/${content.id}`}>
                                        {t('NewsEvents.ReadMore')}
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            </Body>
        )
    }
}
export default withTranslation(['translation'])(NewsEventsClass);
