import React from 'react';

export default function CollectionIcon({ fill }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="146" height="97" viewBox="0 0 146 97" fill="none">
      <g clipPath="url(#clip0_483_4)">
        <path
          d="M105.116 0C104.438 0 103.759 0 103.08 0.0844948C81.7005 1.35192 64.6477 22.4756 64.6477 48.331C64.6477 74.1864 81.7005 95.3101 103.08 96.5775C103.759 96.5775 104.438 96.662 105.116 96.662C127.429 96.662 145.5 75.0314 145.5 48.331C145.5 21.6307 127.429 0 105.116 0ZM138.798 48.331C138.798 56.1045 133.538 62.4416 127.005 62.4416C120.472 62.4416 115.212 56.1045 115.212 48.331C115.212 40.5575 120.472 34.2204 127.005 34.2204C133.538 34.2204 138.798 40.5575 138.798 48.331ZM105.116 8.027C111.649 8.027 116.909 14.3641 116.909 22.1376C116.909 29.9111 111.649 36.2483 105.116 36.2483C98.5836 36.2483 93.3235 29.9111 93.3235 22.1376C93.3235 14.3641 98.5836 8.027 105.116 8.027ZM111.819 48.331C111.819 52.8092 108.764 56.358 105.116 56.358C101.468 56.358 98.4139 52.7247 98.4139 48.331C98.4139 43.9373 101.468 40.304 105.116 40.304C108.764 40.304 111.819 43.8528 111.819 48.331ZM83.2276 62.3571C76.6949 62.3571 71.4349 56.02 71.4349 48.2465C71.4349 40.473 76.6949 34.1359 83.2276 34.1359C89.7602 34.1359 95.0203 40.5575 95.0203 48.331C95.0203 56.1045 89.6754 62.3571 83.2276 62.3571ZM93.3235 74.4399C93.3235 66.6664 98.5836 60.3293 105.116 60.3293C111.649 60.3293 116.909 66.6664 116.909 74.4399C116.909 82.2134 111.649 88.5505 105.116 88.5505C98.5836 88.5505 93.3235 82.2134 93.3235 74.4399Z"
          fill={fill} />
        <path
          d="M92.9843 95.6481C93.663 95.8171 94.3417 95.9861 95.0205 96.0706C93.0691 96.4086 91.033 96.5775 88.9968 96.5775C88.3181 96.5775 87.6394 96.5775 86.9607 96.493C65.5811 95.2256 48.5283 74.1019 48.5283 48.2465C48.5283 22.3911 65.5811 1.26744 86.9607 2.21133e-05C87.6394 2.21133e-05 88.3181 -0.0844727 88.9968 -0.0844727C91.033 -0.0844727 93.0691 0.0845169 95.0205 0.422496C94.3417 0.506991 93.663 0.67598 92.9843 0.84497C74.5741 5.32319 60.6604 24.8415 60.6604 48.162C60.6604 71.4826 74.4893 91.1699 92.9843 95.6481Z"
          fill={fill} />
        <path
          d="M0 76.0454L12.1321 55.9356C45.4741 37.3468 39.6201 73.5105 61.0846 88.8041C67.0233 93.0288 72.8773 95.3102 76.7799 96.1552C60.5755 98.6055 50.9886 95.2257 45.0499 90.663C38.4324 85.6778 37.6688 80.3546 31.3907 76.8058C22.3977 71.8207 10.605 70.9757 0 76.0454Z"
          fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0_483_4">
          <rect width="145.5" height="97" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}