import React from 'react';
import i18next from 'i18next';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Body from '../../templates/Body/Body';
import BodyExercise from '../BodyExercise/BodyExercise';
import ExerciseIcons from '../BodyExercise/ExerciseIcons';
import Loading from '../../components/Loading/Loading';
import { AltDescriptionTranslations, getExerciseDescription, getExerciseText } from '../../utils/ssUtils';
import { BASE_URL } from '../../utils/globals';
import { getStudentSheetById } from '../../api/student-sheets';

import icon from '../../assets/images/student-sheets/readActivity.svg';
import './ExA3.css';

class ExA3Class extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ex: null,
            loading: true
        };
    }

    componentDidMount = async () => {
        const ssId = this.props.match.params.id;
        localStorage.setItem('lastPage', '/exA3');

        let response;
        try {
            response = await getStudentSheetById(ssId);
        } catch (error) {
            console.error('An error occurred: ', error);
            return;
        }

        this.setState({
            loading: false,
            ex: response
        });

        if (localStorage.getItem('disable')) {
            this.setState({ viewMode: true });
        }
    }

    render() {
        const checkToken = localStorage.getItem('token');
        if (!checkToken) { return <Redirect to="/noAccount" />; }

        if (this.state.loading) {
            return (
                <Body
                    bodyClass="content"
                    title="A3 Exercise | CinEd"
                >
                    <Loading />
                </Body>
            );
        }

        const exercise = this.state.ex;
        const description = getExerciseDescription(this.state.ex, 'A3', i18next.language, AltDescriptionTranslations);

        return (
            <BodyExercise
                selectedOption='exA3'
                studentSheetId={this.props.match.params.id}
                movieTitle={exercise?.MovieTitle}
                pageTitle={`A3 Exercise | ${exercise?.MovieTitle} | CinEd`}
                viewMode={this.state.viewMode}
                interactiveEx={false}
                prevLink={`/exA2/${this.props.match.params.id}`}
                nextLink={`/exB1/${this.props.match.params.id}`}
            >
                <ExerciseIcons
                    studentSheetId={this.props.match.params.id}
                    exIcon={icon}
                    exTitle='StudentSheetsLeftMenu.SaidAboutTheFilm'
                    exDescription={description}
                    viewMode={this.state.viewMode}
                />
                
                <div className="exerciseA3Container">
                    <div className="imageDescriptionWrapper">
                        <img
                            className="imageExerciseA3"
                            src={`${BASE_URL}${exercise.A3?.Image?.url}`}
                            alt="Movie Director"
                        />
                        <p className="nameExerciseA3">
                            {exercise.A3?.ImageCaption}
                        </p>
                    </div>

                    <div className="scrollBarWrapper">
                        <div className="scrollBarA3">
                            <p className="textExerciseA3 ssNormalText boldText">
                                {getExerciseText(this.state.ex, 'A3', i18next.language)}
                            </p>
                        </div>
                    </div>
                </div>
            </BodyExercise>
        )
    }
}

export default withTranslation(['translation'])(ExA3Class);
