import React from 'react';
import { useTranslation } from 'react-i18next';

import useWindowDimensions from '../../components/WindowDimensions/WindowDimensions';
import { ReactComponent as Print } from '../../assets/images/dossiers/print.svg';

export default function ResourcesItem(props) {
    const { width } = useWindowDimensions();
    const { t } = useTranslation();
    const dossierClass = width < 991 ? 'btnRegistration smallText' : 'cinedLargeBtn normalText';

    return (
        <div className="itemContainer">
            <div 
                className="resourceImgContainer" 
                style={{ backgroundColor: props.bkgColor }}
                onClick={()=> window.open(props.dossierLink, "_blank")}
            >
                <img src={props.imgSrc} alt={props.imgAlt} />
            </div>

            <div className="resourceContent">
                {props.title ? <h3 className="itemTitle h3Text">
                    {props.title}
                </h3> : <></>}

                {props.subtitle ? <h4 className="itemSubtitle h4Text">
                    {props.subtitle}
                </h4> : <></>}

                <p className="resourcesText normalText">
                    {props.children}
                </p>

                <div className="dossierContainer">
                    <a href={props.dossierLink} className={dossierClass} target="_blank" rel="noreferrer">
                        <span>{t('TeachingResources.Resource')}</span>
                    </a>

                    <div className="printBtn">
                        <a href={props.printableDossier} target="_blank" rel="noreferrer">
                            <Print />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
