const TextInputStyles = {
    '& .MuiInput-root': {
        fontSize: '13px',
        color: 'rgb(57, 11, 110)',
    },
    '& .MuiInputBase-input': {
        paddingLeft: '3px',
    },
    '& .MuiInputLabel-root': {
        fontSize: '13px',
        marginLeft: '3px',
    },
    '& .MuiInputBase-root:before': {
        borderBottom: '2px solid rgba(216, 183, 75, 0.58)',
    },
    '& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
        borderBottom: '2px solid rgba(216, 183, 75, 0.58)',
    },
    '& .MuiFormLabel-asterisk': {
        display: 'none',
    },
    '& .MuiIconButton-root': {
        fontSize: '15px',
        marginRight: '-9px',
    },
};

export default TextInputStyles;
