import React, { Component } from "react";
import ImageGallery from "react-image-gallery";

import { BASE_URL } from "../../utils/globals";
import galleryPrev from "../../assets/images/news-events/galleryPrev.svg";
import galleryNext from "../../assets/images/news-events/galleryNext.svg";

import "./Gallery.css";

export default class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      media: [],
    };
  }

  componentDidMount() {
    if (this.props.img === undefined) {
      const media = this.props.items;
      this.setState({
        media: media,
      });
    } else {
      const media = this.props.img.map((img) => {
        return {
          original: BASE_URL + img.url,
          thumbnail: BASE_URL + img.formats.thumbnail.url,
          description: img.alternativeText,
        };
      });

      this.setState({
        media: media,
      });
    }
  }

  render() {
    let count = 0;
    if (this.props.imgIdx === undefined) {
      count = 0;
    } else {
      count = this.props.imgIdx;
    }
    return (
      <div>
        <ImageGallery
          items={this.state.media}
          startIndex={parseInt(count)}
          lazyLoad={false}
          infinite={true}
          showBullets={false}
          showFullscreenButton={this.props.img === undefined ? false : true}
          showPlayButton={false}
          showThumbnails={true}
          showIndex={false}
          showNav={true}
          isRTL={false}
          thumbnailPosition="bottom"
          slideDuration={parseInt(450)}
          slideInterval={parseInt(2000)}
          slideOnThumbnailOver={false}
          additionalClass="app-image-gallery"
          useWindowKeyDown={true}
          className="gallery"
          renderRightNav={(onClick, disabled) => (
            <button
              type="button"
              className="image-gallery-icon image-gallery-right-nav"
              disabled={disabled}
              onClick={onClick}
              aria-label="Next Slide"
            >
              <img
                className="gallery-arrow"
                alt="Next Slide"
                src={galleryNext}
              />
            </button>
          )}
          renderLeftNav={(onClick, disabled) => (
            <button
              type="button"
              className="image-gallery-icon image-gallery-left-nav"
              disabled={disabled}
              onClick={onClick}
              aria-label="Next Slide"
            >
              <img
                className="gallery-arrow"
                alt="Previous Slide"
                src={galleryPrev}
              />
            </button>
          )}
        />
      </div>
    );
  }
}
