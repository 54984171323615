import React from 'react';
import { withTranslation } from 'react-i18next';

import Modal from '../../templates/Modal/Modal';

import Cinemateca from '../../assets/images/partners/cinemateca.png';
import Arta from '../../assets/images/partners/arta.png';
import Czech from '../../assets/images/partners/czech.png';
import IhmeFilmi from '../../assets/images/partners/ihme-filmi.png';
import MenoAvilys from '../../assets/images/partners/meno-avilys.png';
import CooperativaSociale from '../../assets/images/partners/cooperativa-sociale.png';
import ArteUrbana from '../../assets/images/partners/arte-urbana.png';
import FilhosLumiere from '../../assets/images/partners/filhos-lumiere.png';
import Abaoaqu from '../../assets/images/partners/a-bao-a-qu.png';
import Constrasens from '../../assets/images/partners/contrasens.png';
import MOG from '../../assets/images/partners/mog.svg';
import DFF from '../../assets/images/partners/dff.png';
import GFA from '../../assets/images/partners/gfa.svg';
import Bacaci from '../../assets/images/partners/bacaci.png';
import Filmakademie from '../../assets/images/partners/filmakadimie.png';
import Sakarya from '../../assets/images/partners/sakarya-university.png';

import './ProjectPartners.css';

const Partners = [
    {
        id: 'cinemateca',
        imgSrc: Cinemateca,
        imgAlt: 'Cinemateca Portuguesa',
        title: 'CINEMATECA PORTUGUESA, PORTUGAL',
        link: 'http://www.cinemateca.pt/',
        introPartner: <b>Cinemateca Portuguesa-Museu do Cinema</b>,
        description: 'AboutTheProject.ModalCinematecaText'
    },
    {
        id: 'abaoaqu',
        imgSrc: Abaoaqu,
        imgAlt: 'A Bao A Qu',
        title: 'ASSOCIACIÓ A BAO A QU, SPAIN',
        link: 'https://www.abaoaqu.org/',
        introPartner: <b>Associació A Bao A Qu</b>,
        description: 'AboutTheProject.ModalAbaoaquText'
    },
    {
        id: 'arta',
        imgSrc: Arta,
        imgAlt: 'ARTA',
        title: 'CINEMA ARTA, ROMANIA',
        link: 'https://www.cinema-arta.ro/',
        introPartner: <b>Cinema ARTA</b>,
        description: 'AboutTheProject.ModalArtaText'
    },
    {
        id: 'urbana',
        imgSrc: ArteUrbana,
        imgAlt: 'Arte Urbana',
        title: 'ARTE URBANA COLLECTIF, BULGARIA',
        link: 'https://www.arteurbanacollectif.com/',
        introPartner: <b>Arte Urbana Collectif</b>,
        description: 'AboutTheProject.ModalArteUrbanaText'
    },
    {
        id: 'constrasens',
        imgSrc: Constrasens,
        imgAlt: 'Constrasens',
        title: 'CONTRASENS CULTURAL ASSOCIATION, ROMANIA',
        link: 'https://www.accontrasens.ro/asociatia-culturala-contrasens-timisoara/',
        introPartner: <b>Contrasens Cultural Association</b>,
        description: 'AboutTheProject.ModalConstrasensText'
    },
    {
        id: 'acfk',
        imgSrc: Czech,
        imgAlt: 'AČFK',
        title: 'AČFK, CZECH REPUBLIC',
        link: 'https://acfk.cz/',
        introPartner: <b>Asociace Českých Filmových Klubů</b>,
        description: 'AboutTheProject.ModalCzechText'
    },
    {
        id: 'bacaci',
        imgSrc: Bacaci,
        imgAlt: 'Shadow Casters',
        title: 'BACAČI SJENKI, CROATIA',
        link: 'https://www.bacaci-sjenki.hr/en/',
        introPartner: <><b>Bacači Sjenki</b> (Shadow Casters)</>,
        description: 'AboutTheProject.ModalBacaciText'
    },
    {
        id: 'cooperativa',
        imgSrc: CooperativaSociale,
        imgAlt: 'Cooperativa Sociale',
        title: 'GET COOPERATIVA SOCIALE, ITALY',
        link: 'http://www.getcinema.it/',
        introPartner: <b>GET social cooperative</b>,
        description: 'AboutTheProject.ModalCooperativaSocialeText'
    },
    {
        id: 'filmakademie',
        imgSrc: Filmakademie,
        imgAlt: 'Deutsche Filmakademie',
        title: 'DEUTSCHE FILMAKADEMIE, GERMANY',
        link: 'https://www.deutsche-filmakademie.de/',
        introPartner: <b>Deutsche Filmakademie</b>,
        description: 'AboutTheProject.ModalFkdText'
    },
    {
        id: 'dff',
        imgSrc: DFF,
        imgAlt: 'DFF',
        title: 'DEUTSCHES FILMINSTITUT FILMMUSEUM, GERMANY',
        link: 'https://www.dff.film/en/',
        introPartner: <b>DFF – Deutsches Filminstitut & Filmmuseum</b>,
        description: 'AboutTheProject.ModalDffText'
    },
    {
        id: 'gfa',
        imgSrc: GFA,
        imgAlt: 'Greek Film Archive',
        title: 'GREEK FILM ARCHIVE, GREECE',
        link: 'http://www.tainiothiki.gr/en/',
        introPartner: <>The <b>Greek Film Archive</b></>,
        description: 'AboutTheProject.ModalGFAText'
    },
    {
        id: 'filmi',
        imgSrc: IhmeFilmi,
        imgAlt: 'IhmeFilmi',
        title: 'IHMEFILMI, FINLAND',
        link: 'https://ihmefilmi.fi/',
        introPartner: <b>IhmeFilmi</b>,
        description: 'AboutTheProject.ModalIhmeFilmiText'
    },
    {
        id: 'meno',
        imgSrc: MenoAvilys,
        imgAlt: 'Meno Avilys',
        title: 'MENO AVILYS, LITHUANIA',
        link: 'http://www.menoavilys.org/',
        introPartner: <b>Meno Avilys</b>,
        description: 'AboutTheProject.ModalMenoAvilysText'
    },
    {
        id: 'mog',
        imgSrc: MOG,
        imgAlt: 'MOG Technologies',
        title: 'MOG TECHNOLOGIES, PORTUGAL',
        link: 'https://www.mog-technologies.com/',
        introPartner: <b>MOG Technologies</b>,
        description: 'AboutTheProject.ModalMogText'
    },
    {
        id: 'lumiere',
        imgSrc: FilhosLumiere,
        imgAlt: 'Os Filhos de Lumière',
        title: 'OS FILHOS DE LUMIÈRE, PORTUGAL',
        link: 'http://osfilhosdelumiere.com/home/',
        introPartner: <b>Os Filhos de Lumière</b>,
        description: 'AboutTheProject.ModalFilhosLumiereText'
    },
    {
        id: 'sakarya',
        imgSrc: Sakarya,
        imgAlt: 'Sakarya University',
        title: 'SAKARYA UNIVERSITY, TURKEY',
        link: 'https://rts.sakarya.edu.tr/en',
        introPartner: <>Department of Radio, Television and Cinema in <b>Sakarya University</b> Faculty of Communication</>,
        description: 'AboutTheProject.ModalSakaryaText'
    },
];

class ProjectPartners extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: null
        };
        
        this.setSelectedModal = this.setSelectedModal.bind(this);
    }

    setSelectedModal(selected) {
        this.setState({
            selected: selected
        });
    }

    render() {
        const { t } = this.props;
        const { selected } = this.state;

        return (
            <div className="gridLogos">
                {Partners.map((p) => <div 
                    className="pointer" 
                    key={p.id}
                    onClick={() => this.setSelectedModal(p.id)}
                >
                    <img
                        className="projectLogo"
                        src={p.imgSrc}
                        alt={p.imgAlt}
                    />
                </div>)}

                {Partners.map((p) =>
                    <Modal
                        key={`modal-${p.id}`}
                        title={p.title}
                        titleClass="modalTitleHeader normalText"
                        closeTranslation={t('AboutTheProject.ModalCloseButton')}
                        imgSrc={p.imgSrc}
                        imgAlt={p.imgAlt}
                        link={p.link}
                        open={selected === p.id ? true : false}
                        closeModal={() => this.setSelectedModal(null)}
                    >
                        <div className="modalBody">
                            <p className="textModal smallText">{p.introPartner}{t(p.description)}</p>
                        </div>
                    </Modal>
                )}
            </div>
        )
    }
}

export default withTranslation(['translation'])(ProjectPartners);
