import { apiInfo, fetchRequest, HTTPMethod, Resource } from './api';

export const getEventsNews = () => {
    return fetchRequest(
        HTTPMethod.GET,
        `${apiInfo.base}/${Resource.EVENTS_NEWS}?_sort=Date:desc`
    );
}

export const getEventsNewsById = (id) => {
    return fetchRequest(
        HTTPMethod.GET,
        `${apiInfo.base}/${Resource.EVENTS_NEWS}/${id}`
    );
}