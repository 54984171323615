import React from 'react';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import ArrowsMain from './ArrowsMain';
import Body from '../../templates/Body/Body';
import './BodyExercise.css';

const ExerciseList = {
    'exA1': 'StudentSheetsLeftMenu.SeenInFilm',
    'exA2': 'StudentSheetsLeftMenu.HeardInFilm',
    'exA3': 'StudentSheetsLeftMenu.SaidAboutTheFilm',
    'exB1': 'StudentSheetsLeftMenu.FilmSeveralTitles',
    'exB2': 'StudentSheetsLeftMenu.FilmAndItsEra',
    'exC11': 'StudentSheetsLeftMenu.LearnAndImagine',
    'exC21': 'StudentSheetsLeftMenu.ChoosingCreatingImages',
    'exD': 'StudentSheetsLeftMenu.GoFurther'
};

class BodyExercise extends React.Component {

    getExerciseOption = (ex) => {
        const { selectedOption, studentSheetId, t } = this.props;

        const selectedSectionClass = selectedOption === 'exD' ? 'selectedMainMenuExercise' : 'selectedMenuExercise';
        const sectionClass = ex === 'exD' ? 'sectionMenuExercise' : 'subsectionMenuExercise';

        if (selectedOption === ex) {
            return <p className={selectedSectionClass}>
                {t(ExerciseList[ex])}
            </p>;
        } else {
            return <NavLink to={`/${ex}/${studentSheetId}`}>
                <p className={sectionClass}>{t(ExerciseList[ex])}</p>
            </NavLink>;
        }
    }

    render() {
        const {
            viewMode, movieTitle, pageTitle,
            interactiveEx, prevLink, nextLink, lastEx,
            children, t
        } = this.props;

        const savedCode = localStorage.getItem('code');

        return (
            <Body
                bodyClass="content"
                title={pageTitle}
            >
                <div className="gridContainerStudentSheets">
                    <div className="exerciseMenu">
                        <p className="titleMenuExercise">{movieTitle}</p>

                        <p className="sectionMenuExercise">{t('StudentSheetsLeftMenu.TheFilm')}</p>
                        {this.getExerciseOption('exA1')}
                        {this.getExerciseOption('exA2')}
                        {this.getExerciseOption('exA3')}

                        <p className="sectionMenuExercise">{t('StudentSheetsLeftMenu.InEuropeAndElsewhere')}</p>
                        {this.getExerciseOption('exB1')}
                        {this.getExerciseOption('exB2')}

                        <p className="sectionMenuExercise">{t('StudentSheetsLeftMenu.FilmAndMe')}</p>
                        {this.getExerciseOption('exC11')}
                        {this.getExerciseOption('exC12')}
                        {this.getExerciseOption('exC21')}

                        {this.getExerciseOption('exD')}
                    </div>

                    {!viewMode || !savedCode ? <div>
                        <div className="shareableCodeContainer">
                            <p className="shareableCodeTitle">{t('StudentSheetsLeftMenu.Code')}</p>
                            <p className="codeForm">{savedCode}</p>
                        </div>
                    </div> : <></>}

                    <div className="exerciseBoxForm">
                        {children}

                        <ArrowsMain
                            viewMode={viewMode}
                            interactiveEx={interactiveEx}
                            prevLink={prevLink}
                            nextLink={nextLink}
                            lastEx={lastEx}
                        />
                    </div>
                </div>
            </ Body>
        )
    }
}
export default withTranslation(['translation'])(BodyExercise);