import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Paper, TableContainer, Table, TableHead,
    TableBody, TableRow, TableCell
} from '@mui/material';

import useWindowDimensions from '../WindowDimensions/WindowDimensions';
import { tableContainer, tableHead, tableBody } from './ReadMoreStyles';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const PartnersList = ['P1', 'P2', 'P3', 'P4', 'P5', 'P6', 'P7', 'P8', 'P9', 'P10', 'P11', 'P12', 'P13'];
const AssociatedPartnersList = ['AP1', 'AP2', 'AP3'];

export default function ReadMore() {
    const [open, setOpen] = useState(false);
    const { width } = useWindowDimensions();
    const { i18n, t } = useTranslation();

    return (
        <div>
            <div className="collapsibleDiv" style={{ transform: open ? 'scaleY(1)' : 'scaleY(0)' }}>
                {open ? <div>
                    <hr className="contentMargin" />

                    <p className="resourcesText normalText contentMargin">
                        {t('AboutTheProject.ProjectDesc1')}
                    </p>

                    {i18n.language === 'DE' ? <p className="resourcesText normalText contentMargin">
                        <div>
                            Informationen zur Durchführung von CinEd in Deutschland gibt es hier:
                        </div>

                        <ul>
                            <li>
                                <a href="https://www.dff.film/bildung/modellprojekte/cined/">
                                    DFF – Deutsches Filminstitut & Filmmuseum
                                </a>
                            </li>

                            <li>
                                <a href="https://www.deutsche-filmakademie.de/filmbildung/cined/">
                                    Deutsche Filmakademie
                                </a>
                            </li>
                        </ul>
                    </p>
                        : <></>}

                    <h3 className="resourcesH3 h3Text">
                        {t('AboutTheProject.ProjectQ1')}
                    </h3>

                    <p className="resourcesText normalText">
                        {t('AboutTheProject.ProjectA1')}
                    </p>
                    
                    <h3 className="resourcesH3 h3Text">
                        {t('AboutTheProject.ProjectQ2')}
                    </h3>

                    <p className="resourcesText normalText">
                        {t('AboutTheProject.ProjectA2')}
                    </p>

                    <h3 className="resourcesH3 h3Text">
                        {t('AboutTheProject.ProjectQ3')}
                    </h3>

                    <p className="resourcesText normalText">
                        {t('AboutTheProject.ProjectA3')}
                    </p>

                    <h3 className="resourcesH3 h3Text">
                        {t('AboutTheProject.ProjectQ4')}
                    </h3>

                    <p className="resourcesText normalText">
                        {t('AboutTheProject.ProjectA4')}
                    </p>

                    <p className="resourcesText normalText contentMargin">
                        {t('AboutTheProject.ProjectDesc2')}
                    </p>

                    <p className="resourcesText normalText contentMargin">
                        {t('AboutTheProject.ProjectDesc3')}
                    </p>

                    <h3 className="projectDescTitle h3Text">
                        {t('AboutTheProject.ProjectConsortium')}
                    </h3>

                    {width > 764 ? <TableContainer sx={tableContainer} component={Paper}>
                        <Table>
                            <TableHead sx={tableHead}>
                                <TableRow>
                                    <TableCell>
                                        <span className="normalText">
                                            {t('AboutTheProject.ProjectPartners')}
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        <span className="normalText">
                                            {t('AboutTheProject.ProjectAssociatedPartners')}
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody sx={tableBody}>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {PartnersList.map((pr, i) =>
                                            <div key={i} className="normalText">
                                                <ArrowForwardIcon className="listProjectIcon" />
                                                {t(`AboutTheProject.${pr}`)}
                                            </div>
                                        )}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {AssociatedPartnersList.map((pr, i) =>
                                            <div key={i} className="normalText">
                                                <ArrowForwardIcon className="listProjectIcon" />
                                                {t(`AboutTheProject.${pr}`)}
                                            </div>
                                        )}
                                    </TableCell>
                                </TableRow>
                            </TableBody>

                        </Table>
                    </TableContainer> :
                        <div>
                            <h4 className="tableTitle normalText">
                                {t('AboutTheProject.ProjectPartners')}
                            </h4>
                            {PartnersList.map((pr, i) =>
                                <div key={i} className="resourcesText normalText">
                                    <ArrowForwardIcon className="listProjectIcon" />
                                    {t(`AboutTheProject.${pr}`)}
                                </div>
                            )}

                            <h4 className="tableTitle normalText">
                                {t('AboutTheProject.ProjectAssociatedPartners')}
                            </h4>
                            {AssociatedPartnersList.map((pr, i) =>
                                <div key={i} className="resourcesText normalText">
                                    <ArrowForwardIcon className="listProjectIcon" />
                                    {t(`AboutTheProject.${pr}`)}
                                </div>
                            )}
                        </div>}

                </div> : <></>}
            </div>

            <button className="btnRegistration readMoreMargin normalText" onClick={() => setOpen(!open)}>
                <span>{t(open ? 'AboutTheProject.ReadLess' : 'AboutTheProject.ReadMore')}</span>
            </button>
        </div>
    );
}