import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';
import MetaTags from '../../components/MetaTags/MetaTags';
import UserDropdown from '../../templates/UserDropdown/UserDropdown';
import useWindowDimensions from '../../components/WindowDimensions/WindowDimensions';
import { removeSSFromStorage } from '../../utils/ssUtils';

import trainingImg from '../../assets/images/homepage/training.svg';
import mediatorsImg from '../../assets/images/homepage/mediators.svg';
import screeningsImg from '../../assets/images/homepage/screenings.svg';
import peopleImg from '../../assets/images/homepage/young-people.svg';
import logo from '../../assets/images/logo.svg';
// import { ReactComponent as CollectionIcon } from '../../assets/images/homepage/1-collection.svg';
// import { ReactComponent as CollectionHoverIcon } from '../../assets/images/homepage/1-collection-hover.svg';
// import { ReactComponent as ProjectIcon } from '../../assets/images/homepage/2-project.svg';
// import { ReactComponent as ProjectHoverIcon } from '../../assets/images/homepage/2-project-hover.svg';
// import { ReactComponent as ContactsIcon } from '../../assets/images/homepage/3-contacts.svg';
// import { ReactComponent as ContactsHoverIcon } from '../../assets/images/homepage/3-contacts-hover.svg';
// import { ReactComponent as AtlasIcon } from '../../assets/images/homepage/4-films.svg';
// import { ReactComponent as AtlasHoverIcon } from '../../assets/images/homepage/4-films-hover.svg';

import './Homepage.css';
import CollectionIcon from '../../components/HomeIcon/CollectionIcon';
import ProjectIcon from '../../components/HomeIcon/ProjectIcon';
import ContactsIcon from '../../components/HomeIcon/ContactsIcon';
import AtlasIcon from '../../components/HomeIcon/AtlasIcon';
import HomeIcon from './HomeIcon';

const TextHP = [
    {
        containerClass: 'gridText1',
        fadeClass: 'fadeIn4s',
        slogan: 'Homepage.EducationalProcess'
    },
    /*{
        containerClass: 'gridText2',
        fadeClass: 'fadeIn5s',
        slogan: 'Homepage.Learning'
    },*/
    {
        containerClass: 'gridText3',
        fadeClass: 'fadeIn35s',
        slogan: 'Homepage.DynamicClasses'
    },
    {
        containerClass: 'gridText4',
        fadeClass: 'fadeIn4s',
        slogan: 'Homepage.SocioCultural'
    },
    /*{
        containerClass: 'gridText5',
        fadeClass: 'fadeIn4s',
        slogan: 'Homepage.SocioCultural'
    },*/
    {
        containerClass: 'gridText6',
        fadeClass: 'fadeIn5s',
        slogan: 'Homepage.EuropeanStudents'
    }
];

const NumbersHP = [
    {
        imgSrc: trainingImg,
        imgAlt: 'Number of Trainings',
        number: '200+',
        slogan: 'Homepage.Trainings'
    },
    {
        imgSrc: mediatorsImg,
        imgAlt: 'Number of Mediators',
        number: '4000+',
        slogan: 'Homepage.Mediators'
    },
    {
        imgSrc: screeningsImg,
        imgAlt: 'Number of Screenings',
        number: 1200,
        slogan: 'Homepage.Screenings'
    },
    {
        imgSrc: peopleImg,
        imgAlt: 'Number of Young People Trained',
        number: '44000+',
        slogan: 'Homepage.YoungPeople'
    }
];

export default function Homepage(props) {
    const { t } = useTranslation();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [hoveredOption, setHoveredOption] = useState('');

    const { width } = useWindowDimensions();
    const isMobile = width < 991 ? true : false;

    useEffect(() => {
        const token = localStorage.getItem('token');
        const userJSON = localStorage.getItem('user');
        const user = userJSON ? JSON.parse(userJSON) : null;
        if (token !== null) {
            setIsLoggedIn(true);
            setUser(user);
        }
    }, []);

    const logout = () => {
        removeSSFromStorage();

        localStorage.removeItem('token');
        localStorage.removeItem('user');

        window.location.href = '/';
    }

    return (

        <div className="homepage-container">
            <div className="app-background" />

            <MetaTags />

            <div className="hp-navbar">
                <UserDropdown
                    isUserLoggedIn={isLoggedIn}
                    user={user}
                    logout={logout}
                />

                <LanguageSelector navbar={false} />
            </div>


            <div className="logoWrapper">
                <img src={logo} className="logo" alt="logo" />
                <div className="slogan">
                    European Cinema<br />
                    Education for Youth
                </div>
            </div>

            {TextHP.map((item, idx) =>
                <div key={idx} className={item.containerClass}>
                    <div className={`fadeInText ${item.fadeClass}`}>
                        {t(item.slogan)}
                    </div>
                </div>
            )}

            <div className="gridContainer">
                <div className="gridItem">
                    <NavLink to="/collection">
                        {isMobile ?
                            <button className="btnMovie">
                                {t('Homepage.Movies')}
                            </button> :
                            <HomeIcon
                                optionName="collection"
                                hoveredOption={hoveredOption}
                                setHoveredOption={setHoveredOption}
                                fillColor='#D4AF37'
                                label={t('Homepage.Movies')}
                                Icon={CollectionIcon}
                            />
                        }
                    </NavLink>
                </div>

                <div className="gridItem">
                    <NavLink to="/about-project">
                        {isMobile ?
                            <button className="btnNew">
                                {t('Homepage.About')}
                            </button> :
                            <HomeIcon
                                optionName="project"
                                hoveredOption={hoveredOption}
                                setHoveredOption={setHoveredOption}
                                fillColor='#BFBFBF'
                                label={t('Homepage.About')}
                                Icon={ProjectIcon}
                            />
                        }
                    </NavLink>
                </div>

                <div className="gridItem">
                    <NavLink to="/contacts">
                        {isMobile ?
                            <button className="btnProject">
                                {t('Homepage.Project')}
                            </button> :
                            <HomeIcon
                                optionName="contacts"
                                hoveredOption={hoveredOption}
                                setHoveredOption={setHoveredOption}
                                fillColor='#009999'
                                label={t('Homepage.Project')}
                                Icon={ContactsIcon}
                            />
                        }
                    </NavLink>
                </div>

                <div className="gridItem">
                    <a href="https://atlas.cined.eu" target="_blank"
                        rel="noopener noreferrer">
                        {isMobile ?
                            <button className="btnAtlas">
                                {t('Homepage.Atlas')}
                            </button> :
                            <HomeIcon
                                optionName="atlas"
                                hoveredOption={hoveredOption}
                                setHoveredOption={setHoveredOption}
                                fillColor='#3C216B'
                                label={t('Homepage.Atlas')}
                                Icon={AtlasIcon}
                            />
                        }
                    </a>
                </div>
            </div>

            <div className="numberContainer">
                {NumbersHP.map((item, idx) =>
                    <div key={idx} className="numberItem">
                        <img src={item.imgSrc} alt={item.imgAlt} />
                        <div className="numberValue">
                            {item.number}
                        </div>
                        <div className="numberDescription">
                            {t(item.slogan)}
                        </div>
                    </div>)}
            </div>
        </div>
    );
}