import React from 'react';
import { useTranslation } from 'react-i18next';

import Body from '../../templates/Body/Body';
import ReadMore from '../../components/ProjectPage/ReadMore';
import Testimonials from '../../components/ProjectPage/Testimonials';
import ProjectPartners from '../../components/ProjectPage/ProjectPartners';
import ProjectSupporters from '../../components/ProjectPage/ProjectSupporters';
import ExternalResources from '../../components/ProjectPage/ExternalResources';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Whitebook from '../../assets/docs/whitebook.pdf';
import './AboutTheProject.css';

export default function AboutTheProject(props) {
    const { t } = useTranslation();

    return (
        <Body
            bodyClass="content projectPage"
            title={`${t('NavBar.Project')} | CinEd`}
        >
            <div className="container pageContainer">
                <h1 className="contactsTitle h1Text">
                    {t('Homepage.About')}
                </h1>

                <h2 className="contactsSubTitle h3Text">
                    {t('AboutTheProject.ProjectSubtitle')}
                </h2>

                <h3 className="projectDescTitle h3Text">
                    {t('AboutTheProject.TextBeforeIntroduction')}
                </h3>

                <p className="projectText normalText">
                    {t('AboutTheProject.TextIntroduction')}
                </p>

                <h3 className="projectDescTitle h3Text">
                    {t('AboutTheProject.CinedFosters')}
                </h3>

                <p className="projectText normalText projectItem">
                    <ArrowForwardIcon className="listProjectIcon" /> {t('AboutTheProject.CinedFostersF')}
                </p>
                <p className="projectText normalText projectItem">
                    <ArrowForwardIcon className="listProjectIcon" /> {t('AboutTheProject.CinedFostersS')}
                </p>
                <p className="projectText normalText projectItem">
                    <ArrowForwardIcon className="listProjectIcon" /> {t('AboutTheProject.CinedFostersT')}
                </p>

                <p className="projectText normalText textBeforeTestimonials">
                    {t('AboutTheProject.TextBeforeTestimonials')}
                </p>

                <ReadMore />

                <Testimonials />

                <p className="projectText normalText sectionClosing">{t('AboutTheProject.TextBeforeFooter')}</p>

                <p className="projectText normalText whitebookSection">
                    <span className="whitebookDescription">
                        {t('AboutTheProject.WhiteBookDescription')}
                    </span>

                    <a href={Whitebook} className="cinedBtn bigText" target="_blank" rel="noreferrer">
                        <span>{t('AboutTheProject.WhiteBookTitle')}</span>
                    </a>
                </p>

                <h1 className="projectTitle h1Text">
                    {t('AboutTheProject.PartnersTitle')}
                </h1>

                <ProjectPartners />

                <h1 className="projectTitle h1Text">
                    {t('AboutTheProject.SupportersTitle')}
                </h1>

                <p className="projectText normalText sectionIntro">
                    {t('AboutTheProject.SupportersDescription')}
                </p>

                <ProjectSupporters />

                <h1 className="projectTitle h1Text">
                    {t('Teachers.EuropeanInternationalWebsites')}
                </h1>

                <p className="projectText normalText sectionIntro">
                    {t('Teachers.EuropeanText')}
                </p>

                <ExternalResources />

                <p className="projectText normalText sectionClosing">
                    {t('Teachers.EuropeanSecondText')}
                </p>
            </div>
        </Body>
    );
}