import React from 'react';

import cultureFundBulgaria from '../../assets/images/supporters/1-bulgaria.svg';
import ministerioCulturaSpain from '../../assets/images/supporters/2-ministerio.png';
import institutoCervantes from '../../assets/images/supporters/3-cervantes.png';
import aceFilm from '../../assets/images/supporters/4-ace.png';
import cncGov from '../../assets/images/supporters/5-cnc.png';
import czech from '../../assets/images/supporters/7-czech.png';
import fiaf from '../../assets/images/supporters/9-fiaf.png';
import opetus from '../../assets/images/supporters/10-opetus.png';
import serpa from '../../assets/images/supporters/11-serpa.jpg';
import ica from '../../assets/images/supporters/12-ica.png';
import cnipa from '../../assets/images/supporters/13-cnipa.png';
import sintra from '../../assets/images/supporters/14-sintra.png';
import sofiaProgramme from '../../assets/images/supporters/15-sofia-programme.jpg';
import lisboa from '../../assets/images/supporters/16-lisboa.webp';
import sofiaCityOfFilm from '../../assets/images/supporters/17-sofia-city-of-film.jpg';
import commune from '../../assets/images/supporters/18-commune.png';
import ured from '../../assets/images/supporters/19-ured.png';
import havc from '../../assets/images/supporters/20-havc.jpg';

const Supporters = [
    {
        imgSrc: aceFilm,
        imgAlt: 'Association des Cinémathèques Européennes',
        link: 'https://ace-film.eu/'
    },
    {
        imgSrc: cncGov,
        imgAlt: 'Centrul Național al Cinematografiei',
        link: 'http://cnc.gov.ro/'
    },
    {
        imgSrc: cnipa,
        imgAlt: 'CNIPA PUGLIA',
        link: 'http://www.cnipapuglia.it/wp/'
    },
    {
        imgSrc: commune,
        imgAlt: 'Comune di Bari',
        link: 'http://www.comune.bari.it/'
    },
    {
        imgSrc: havc,
        imgAlt: 'Croatian audiovisual centre',
        link: 'https://havc.hr/'
    },
    {
        imgSrc: fiaf,
        imgAlt: 'International Federation of Film Archives',
        link: 'https://www.fiafnet.org/'
    },
    {
        imgSrc: ica,
        imgAlt: 'Instituto do Cinema e do Audiovisual',
        link: 'https://www.ica-ip.pt/pt/'
    },
    {
        imgSrc: institutoCervantes,
        imgAlt: 'Instituto Cervantes',
        link: 'https://www.cervantes.es/default.htm'
    },
    {
        imgSrc: lisboa,
        imgAlt: 'Câmara Municipal de Lisboa',
        link: 'https://www.lisboa.pt/'
    },
    {
        imgSrc: czech,
        imgAlt: 'Ministry of Culture Czech Republic',
        link: 'https://www.mkcr.cz/?lang=en'
    },
    {
        imgSrc: ministerioCulturaSpain,
        imgAlt: 'Ministerio de Cultura y Deporte',
        link: 'https://www.culturaydeporte.gob.es/portada.html'
    },
    {
        imgSrc: cultureFundBulgaria,
        imgAlt: 'National Culture Fund of Bulgaria',
        link: 'https://ncf.bg/bg'
    },
    {
        imgSrc: opetus,
        imgAlt: 'Opetus - ja kulttuuriministeriö',
        link: 'https://minedu.fi/etusivu'
    },
    {
        imgSrc: serpa,
        imgAlt: 'Câmara Municipal de Serpa',
        link: 'https://www.cm-serpa.pt/pt/Default.aspx'
    },
    {
        imgSrc: sintra,
        imgAlt: 'Câmara Municipal de Sintra',
        link: 'https://cm-sintra.pt/'
    },
    {
        imgSrc: sofiaCityOfFilm,
        imgAlt: 'Sofia City of Film',
        link: 'https://citiesoffilm.org/sofia/'
    },
    {
        imgSrc: sofiaProgramme,
        imgAlt: 'Столична община - Дирекция Култура',
        link: 'https://kultura.sofia.bg/home.html'
    },
    {
        imgSrc: ured,
        imgAlt: 'Ured za udruge',
        link: 'https://udruge.gov.hr/'
    }
];

export default class ProjectSupporters extends React.Component {
    render() {
        return (
            <div className="gridLogos">
                {Supporters.map((p, index) => <a
                    key={index}
                    href={p.link}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div className="pointer">
                        <img
                            className="projectLogo"
                            src={p.imgSrc}
                            alt={p.imgAlt}
                        />
                    </div>
                </a>)}
            </div>
        )
    }
}
