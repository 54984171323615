import React from 'react';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

import Loading from '../../components/Loading/Loading';
import VideoCard from '../../components/VideoCard/VideoCard';
import { getTranslation, lang } from '../../utils/utils';
import { getPedagogicalVideos } from '../../api/pedagogical-videos';
import './PedagogicalVideos.css';

const Prefix = 'translations.';
const DefaultTranslation = Prefix + 'english';

class PedagogicalVideos extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pedagogicalVideos: [],
            loading: true
        };
    }

    async componentDidMount() {
        let videosList;

        try {
            videosList = await getPedagogicalVideos();
        } catch (error) {
            console.error('An error occurred: ', error);
            return;
        }

        this.setState({
            loading: false,
            pedagogicalVideos: videosList
        });
    }

    getMovieTranslation(entry) {
        return getTranslation(entry, i18next.language, Prefix, DefaultTranslation);
    }

    render() {
        const { t } = this.props;
        const listContent = this.state.pedagogicalVideos;

        if (this.state.loading) { return <Loading />; }

        return (
            <div className="content">
                <div className="thematicHeader">
                    <h1 className="sectionTitle" lang={lang[i18next.language]}>
                        {t('Collection.PedagogicalVideo')}
                    </h1>

                    <p className="projectText bigText pedagogicalIntroduction">
                        {t('Collection.PedagogicalVideoIntroduction')}
                    </p>
                </div>
                
                <div className="videoItemsList">
                    {listContent.map((video) =>
                        <VideoCard
                            key={video.id}
                            movie={video}
                            isMovie={false}
                        />
                    )}
                </div>
            </div>
        )
    }
}

export default withTranslation(['translation'])(PedagogicalVideos);
