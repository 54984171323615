import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Link, Redirect } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ReactPaginate from 'react-paginate';

import Body from '../../templates/Body/Body';
import Loading from '../../components/Loading/Loading';
import MetaTags from '../../components/MetaTags/MetaTags';
import { orderMovie } from '../../api/movies';
import { getOrdersByUserId } from '../../api/orders';
import { orderPedagogicalVideo } from '../../api/pedagogical-videos';

import ClipLoader from 'react-spinners/ClipLoader';
import './Orders.css';

const Orders = () => {
    const [orders, setOrders] = useState();
    const [currentDate, setCurrentDate] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [orderLoading, setOrderLoading] = useState([]);

    //pagination hooks
    const [pageNumber, setPageNumber] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(6);
    const [pages, setPages] = useState();
    const indexOfLastItem = pageNumber * itemsPerPage;
    const [currentItems, setCurrentItems] = useState();

    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            let res = await getOrdersByUserId();
            setOrders(res.result);
            setIsLoading(false);
        };

        fetchData().catch((error) => {
            toast.error(t('MyOrders.ErrorOcurred'));
            setIsLoading(false);
            console.error(error)
        });

        const date = new Date();

        setCurrentDate(date.toJSON().slice(0, 10));
    }, []);

    useEffect(() => {
        if (!orders) return;

        //Get pages number
        setPages(Math.ceil(orders?.length / itemsPerPage));

        setCurrentItems(
            orders?.slice(indexOfLastItem, indexOfLastItem + itemsPerPage)
        );
    }, [orders, itemsPerPage, indexOfLastItem]);


    //PAGINATION LOGIC

    //Change page
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const makeAnOrder = async (order) => {
        setOrderLoading((orders) => [...orders, order.id]);

        let res;
        if (order.movie) {
            try {
                res = await orderMovie(order.movie.id);
                toast.success(t('Player.OrderSuccessful'));
            } catch (error) {
                toast.error(t('Player.OrderNotSuccessful'));
                console.error('An error occurred: ', error);
            }
        } else {
            try {
                res = await orderPedagogicalVideo(order.pedagogical.id);
                toast.success(t('Player.OrderSuccessful'));
            } catch (error) {
                toast.error(t('Player.OrderNotSuccessful'));
                console.error('An error occurred: ', error);
            }
        }

        setOrderLoading((orders) => orders.filter(o => o !== order.id));

        setOrders((prevState) => {
            let findOrderIndex = prevState.findIndex(
                (order) => order.id === res.result.id
            );

            let clonedArray = [...prevState];
            clonedArray[findOrderIndex] = res.result;

            return clonedArray;
        });
    };

    const renderOrderAction = (order) => {
        if (currentDate > order.expiryDate) {
            if (orderLoading.includes(order?.id)) {
                return (
                    <ClipLoader
                        color="#3e3e3e"
                        loading={true}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                );
            } else {
                return (
                    <button className="orderBtn" onClick={() => makeAnOrder(order)}>
                        {t('MyOrders.Reorder')}
                    </button>
                );
            }
        }
    };

    const getVideoLink = (order) => {
        const resource = order.movie ? order.movie : order.pedagogical;
        const videoResource = order.movie ? 'movies' : 'pedagogical-videos';

        return (<Link to={`/${videoResource}/${resource?.id}`}>
            {resource.OriginalTitle}
        </Link>);
    };

    const checkToken = localStorage.getItem('token');
    if (!checkToken) { return <Redirect to="/noAccount" />; }

    if (isLoading) {
        return (
            <Body bodyClass="ordersPage" title="My orders">
                <Loading />
            </Body>
        );
    }

    return (
        <Body bodyClass="ordersPage" title="My orders">
            <MetaTags title={`${t('NavBar.Orders')} | CinEd`} />

            <div className="ordersContent">
                <div className="myOrders">
                    <h3 className="h3Text">
                        {t('NavBar.Orders')}
                    </h3>
                    {orders ? <div className="ordersNumber">
                        {orders?.length + ' ' + t('MyOrders.Orders')}
                    </div> : <></>}
                </div>

                <TableContainer>
                    <Table className="ordersTable">
                        <TableHead>
                            <TableRow className="tableHead">
                                <TableCell>{t('MyOrders.Movie')}</TableCell>
                                <TableCell align="center">{t('MyOrders.OrderDate')}</TableCell>
                                <TableCell align="center">{t('MyOrders.ExpiresOn')}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!currentItems || currentItems?.length === 0 ?
                                <TableRow className="movieRow">
                                    <TableCell
                                        className="movieRow"
                                        align="center"
                                        colSpan={4}
                                    >
                                        {t("MyOrders.NoOrders")}
                                    </TableCell>
                                </TableRow>

                                : <></>}
                            {currentItems?.map((order) => (
                                <TableRow
                                    key={order.movie ? order.movie.id : order.pedagogical.id}
                                    className="movieRow"
                                >
                                    <TableCell>
                                        <div className="movieCell">
                                            <label>
                                                {getVideoLink(order)}
                                            </label>
                                            <label>
                                                {order.movie
                                                    ? order.movie.Type + " - " + order.movie.Duration
                                                    : order.pedagogical.Type +
                                                    " - " +
                                                    order.pedagogical.Duration}
                                            </label>
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">
                                        {order.requisitionDate}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        style={
                                            currentDate > order.expiryDate
                                                ? { color: "#ff4646" }
                                                : { color: "#57C84D" }
                                        }
                                    >
                                        {order.expiryDate}
                                    </TableCell>
                                    <TableCell className="orderColumn" align="right">
                                        {renderOrderAction(order)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {!currentItems || currentItems?.length === 0 ?
                    <></> :
                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        pageCount={pages}
                        onPageChange={changePage}
                        containerClassName={"paginationBtns"}
                        previousLinkClassName={"controllersBtn"}
                        nextLinkClassName={"controllersBtn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"activePagination"}
                    />}
            </div>
        </Body>
    );
};

export default Orders;
