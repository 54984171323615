import { apiInfo, fetchRequest, HTTPMethod, Resource } from './api';

export const getPedagogicalVideos = () => {
    return fetchRequest(
        HTTPMethod.GET,
        `${apiInfo.base}/${Resource.PEDAGOGICAL_VIDEOS}`
    );
}

export const getPedagogicalVideosById = (videoId) => {
    return fetchRequest(
        HTTPMethod.GET,
        `${apiInfo.base}/${Resource.PEDAGOGICAL_VIDEOS}/${videoId}`
    );
}

export const orderPedagogicalVideo = (videoId) => {
    return fetchRequest(
        HTTPMethod.POST,
        `${apiInfo.base}/${Resource.PEDAGOGICAL_VIDEOS}/order/${videoId}`,
        null,
        true
    );
}