import React from 'react';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

import { LocaleTranslations, Translations } from '../../utils/utils';

import { ReactComponent as DropDownIcon } from '../../assets/images/navBar.svg';
import ArrowLeftIcon from '../../assets/images/dropSide.svg';
import './LanguageSelector.css';

class LanguageSelector extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedLanguage: 'EN'
        };
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        const lng = i18next.language?.split('-')[0];
        let lang = '';

        if (lng) { lang = LocaleTranslations[lng?.toLowerCase()]; }
        if (lang?.toLowerCase() !== i18next.language?.toLowerCase()) {
            i18next.changeLanguage(lang ? lang : 'US');
        }

        this.setState({
            selectedLanguage: lang === 'US' ? 'EN' : lang
        });
    }

    handleClick(lang) {
        i18next.changeLanguage(lang);

        this.setState({
            selectedLanguage: lang === 'US' ? 'EN' : lang
        });
    }

    render() {
        const { navbar } = this.props;
        const { selectedLanguage } = this.state;

        return (
            <div className={navbar ? "dropdown" : "hp-dropdown"}>
                <div className="changeLanguageWrapper">
                    <button className="dropbtn">
                        {selectedLanguage}
                    </button>

                    <DropDownIcon 
                        id={navbar ? "dropdownNavbar" : "dropdownHP"} 
                        className="dropbtn"
                    />

                    {navbar ? <img 
                        className="dropbtnSide" 
                        src={ArrowLeftIcon}
                        alt="Arrow Left Icon"
                    /> : <></>}
                </div>

                <div className="lngDropdowncontent">
                    {Object.keys(Translations).map((lang, idx) =>
                        <li key={idx} onClick={() => this.handleClick(lang)}>
                            {lang === 'US' ? 'EN' : lang}
                        </li>
                    )}
                </div>
            </div>
        );
    }
}

export default withTranslation(['translation'])(LanguageSelector);