import React from 'react';
import moment from 'moment';
import { Redirect } from 'react-router';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import MetaTags from '../../components/MetaTags/MetaTags';
import NavBarClass from '../../templates/NavBar/NavBar';
import { refreshSession, updateUser } from '../../api/users';
import { Roles } from '../../utils/utils';
import { validateForm } from '../../utils/regex';

import background from '../../assets/images/aniki-bobo.webp';
import ProfileForm from '../../components/ProfileForm/ProfileForm';

class Profile extends React.Component {
    constructor() {
        super();
        this.state = {
            userId: null,
            name: '',
            username: '',
            email: '',
            country: '',
            city: '',
            password: '',
            role: Roles.teacher,
            institution: 'Cultural Structure or Association',
            school: '',
            telephone: '',
            birthDate: undefined,
            question: '',
            answer: '',
            successMessage: '',
            errorMessage: '',
            passwordShown: false,
            disabled: false,
            loading: true,
            isTeacher: true,
        };
        this.selectCity = this.selectCity.bind(this);
        this.selectCountry = this.selectCountry.bind(this);
        this.togglePasswordVisiblity = this.togglePasswordVisiblity.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    async componentDidMount() {
        const { t } = this.props;

        let user;
        try {
            user = await refreshSession();
        } catch (error) {
            toast.error(t('MyOrders.ErrorOcurred'));
            console.error('An error occurred: ', error);
            this.setState({ loading: false, disabled: true, errorMessage: t('MyOrders.ErrorOcurred') });
            return;
        }

        const isTeacher = user.userRole !== 'Student';

        this.setState({
            userId: user.id,
            name: user.name,
            username: user.username,
            email: user.email,
            country: user.country,
            city: user.city,
            role: user.userRole ? user.userRole : this.state.role,
            institution: user.institution ? user.institution : this.state.institution,
            school: user.school,
            telephone: user.telephone,
            birthDate: moment(user.birthDate),
            question: user.securityQuestion,
            answer: user.securityAnswer,
            isTeacher: isTeacher,
            loading: false,
        });
    }

    onChange = (e) => {
        if (e.target.name === 'username' || e.target.name === 'email') {
            this.setState({ [e.target.name]: e.target.value.trim() });
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
    };

    onDateChange = (val) => {
        this.setState({ birthDate: val });
    };

    selectCountry(val) {
        this.setState({ country: val });
    }

    selectCity(val) {
        this.setState({ city: val });
    }

    togglePasswordVisiblity = () => {
        this.setState({ passwordShown: !this.state.passwordShown });
    };

    async handleFormSubmit(e) {
        e.preventDefault();

        this.setState({
            successMessage: this.props.t('SignInUp.Loading') + ' ...',
            errorMessage: '',
        });

        const { error, message } = validateForm(this.state.username, this.state.password);

        if (error) {
            this.setState({
                successMessage: '',
                errorMessage: this.props.t(message),
            });
            return;
        }

        const {
            name,
            username,
            email,
            telephone,
            birthDate,
            country,
            city,
            school,
            role,
            institution,
            question,
            answer,
            password,
            userId,
            isTeacher,
        } = this.state;

        let data = {
            username: username,
            email: email,
            name: name,
            country: country,
            city: city,
            migrationToken: null,
            securityQuestion: question,
            securityAnswer: answer,
        };

        if (isTeacher) {
            data = {
                ...data,
                telephone: telephone,
                birthDate: moment(birthDate).format('YYYY-MM-DD'),
                institution: institution,
                userRole: role,
            };
        } else {
            data = {
                ...data,
                school: school,
                userRole: Roles.student,
            };
        }

        if (password) {
            data = { ...data, password: password };
        }

        let res;
        try {
            res = await updateUser(userId, data);
        } catch (error) {
            const errorData = error?.data;
            const errorMessage = errorData && errorData.length > 0 ? errorData[0]?.messages : null;
            const message = errorMessage && errorMessage.length > 0 ? errorMessage[0] : null;
            let errorMsg = this.props.t('MyOrders.ErrorOcurred');

            switch (message?.id) {
                case 'Auth.form.error.email.taken':
                    errorMsg = this.props.t('SignInUp.EmailTaken');
                    break;
                case 'Auth.form.error.username.taken':
                    errorMsg = this.props.t('SignInUp.UsernameTaken');
                    break;
                default:
                    errorMsg = message ? message.message : this.props.t('MyOrders.ErrorOcurred');
            }

            // Handle error.
            this.setState({
                errorMessage: errorMsg,
                successMessage: '',
            });
            return;
        }

        if (res) {
            localStorage.setItem('user', JSON.stringify(res, null, 2));
        }

        // Handle success.
        this.setState({
            successMessage: this.props.t('Profile.ProfileUpdated'),
            errorMessage: '',
        });
    }

    render() {
        const { t } = this.props;
        const {
            name,
            username,
            email,
            telephone,
            birthDate,
            country,
            city,
            role,
            institution,
            school,
            question,
            answer,
            password,
            isTeacher,
            passwordShown,
            loading,
            disabled,
            errorMessage,
            successMessage,
        } = this.state;

        const checkToken = localStorage.getItem('token');
        if (!checkToken) {
            return <Redirect to="/noAccount" />;
        }

        return (
            <div className="App" style={{ backgroundImage: `url(${background})` }}>
                <MetaTags title={`${t('NavBar.MyProfile')} | CinEd`} />
                <NavBarClass />

                <ProfileForm
                    title={t('NavBar.MyProfile')}
                    name={name}
                    username={username}
                    email={email}
                    telephone={telephone}
                    birthDate={birthDate}
                    country={country}
                    city={city}
                    role={role}
                    institution={institution}
                    school={school}
                    question={question}
                    answer={answer}
                    password={password}
                    isTeacher={isTeacher}
                    passwordShown={passwordShown}
                    loading={loading}
                    disabled={disabled}
                    errorMessage={errorMessage}
                    successMessage={successMessage}
                    myProfile={true}
                    handleFormSubmit={this.handleFormSubmit}
                    onChange={this.onChange}
                    onDateChange={this.onDateChange}
                    selectCountry={this.selectCountry}
                    selectCity={this.selectCity}
                    togglePasswordVisiblity={this.togglePasswordVisiblity}
                />

                <div className="signInUpPlaceholder" />
            </div>
        );
    }
}

export default withTranslation(['translation'])(Profile);
