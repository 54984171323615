import React from 'react';
import { withTranslation } from 'react-i18next';

import Modal from '../../templates/Modal/Modal';
import { getUserByUsername, updatePassword } from '../../api/users';
import { validateForm } from '../../utils/regex';

import VisibilityIcon from '@mui/icons-material/Visibility';

const eye = <VisibilityIcon fontSize="medium" />;

class ModalForgetPassword extends React.Component {
    constructor() {
        super();

        this.state = {
            username: '',
            question: '',
            answer: '',
            password: '',
            errorMessage: '',
            successMessage: '',
            passwordShown: false,
            user: null,
            getUsers: [],
            showFields: false,
        };
    }

    onChange = (e) => {
        if (e.target.name === 'username') {
            this.setState({ [e.target.name]: e.target.value.trim() });
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
    };

    togglePasswordVisiblity = () => {
        this.setState({ passwordShown: !this.state.passwordShown });
    };

    resetPassword = async (e) => {
        // Verificar a resposta do utilizador e atualizar a sua password
        e.preventDefault();

        this.setState({
            successMessage: this.props.t('SignInUp.CheckingAnswer'),
            errorMessage: '',
        });

        const { error, message } = validateForm(null, this.state.password);

        if (error) {
            this.setState({
                successMessage: '',
                errorMessage: this.props.t(message),
            });
            return;
        }

        try {
            await updatePassword(this.state.user.id, this.state.answer, this.state.password);
        } catch (error) {
            let errorMessage = this.props.t('MyOrders.ErrorOcurred');
            if (error?.data?.id === 'answer.error') {
                errorMessage = this.props.t('SignInUp.WrongAnswer');
            } else if (error?.data?.message) {
                errorMessage = error?.data?.message;
            }

            this.setState({
                errorMessage: errorMessage,
                successMessage: '',
            });
            return;
        }

        this.setState({
            successMessage: this.props.t('SignInUp.PasswordChanged'),
            errorMessage: '',
        });
    };

    searchSecurityQuestion = async (e) => {
        e.preventDefault();

        // Procurar username, e carregar questão de segurança
        if (!this.state.username) {
            this.setState({
                errorMessage: this.props.t('SignInUp.ValidUsername'),
            });
            return;
        }

        let res, user;
        try {
            res = await getUserByUsername(this.state.username);
        } catch (error) {
            this.setState({
                errorMessage: this.props.t('SignInUp.ValidUsername'),
                successMessage: '',
                showFields: false,
            });
            return;
        }

        if (res.length > 0) {
            user = res[0];
        } else {
            this.setState({
                errorMessage: this.props.t('SignInUp.ValidUsername'),
                successMessage: '',
                showFields: false,
            });
            return;
        }

        const userQuestion = user.securityQuestion;
        this.setState({
            user: user,
            question: userQuestion,
            showFields: true,
            successMessage: '',
            errorMessage: '',
        });
    };

    render() {
        const { t, open, closeModal } = this.props;
        const { answer, passwordShown, username, password } = this.state;

        return (
            <Modal
                id="cs"
                titleClass="signInModalTitle h4Text"
                title={t('SignInUp.ResetPass')}
                closeTranslation={'SignInUp.Close'}
                open={open}
                closeModal={() => closeModal()}
            >
                <div className="modalBody">
                    <form onSubmit={this.resetPassword}>
                        <div className="forgotInputWrapper">
                            <div className="submitUsernameWrapper">
                                <input
                                    className="inputRegister"
                                    type="text"
                                    name="username"
                                    placeholder={t('SignInUp.EnterUsername')}
                                    value={username}
                                    onChange={this.onChange}
                                    required={true}
                                />
                                <button onClick={this.searchSecurityQuestion} className="submitUsername smallText">
                                    Ok
                                </button>
                            </div>

                            {this.state.showFields ? (
                                <div>
                                    <input
                                        className="inputRegister"
                                        type="text"
                                        placeholder={this.state.question}
                                        disabled={true}
                                    />
                                    <div className="fieldsDivision" />

                                    <input
                                        className="inputRegister"
                                        type="text"
                                        name="answer"
                                        placeholder={t('SignInUp.EnterAnswer')}
                                        value={answer}
                                        onChange={this.onChange}
                                        required={true}
                                    />
                                    <div className="fieldsDivision" />

                                    <div className="passwordRegisterWrappper">
                                        <input
                                            className="inputRegister"
                                            type={passwordShown ? 'text' : 'password'}
                                            name="password"
                                            value={password}
                                            placeholder={t('SignInUp.NewPassword')}
                                            onChange={this.onChange}
                                            required={true}
                                        />

                                        <i className="eyeSignUp" onClick={this.togglePasswordVisiblity}>
                                            {eye}
                                        </i>

                                        <div className="fieldsDivision" />
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>

                        <div className={this.state.showFields ? 'active-arrows' : 'inactive-arrows'}>
                            <button type="submit" className="submitRegistration detailsText">
                                {t('SignInUp.ResetPass')}
                            </button>
                        </div>

                        {this.state.successMessage ? <p className="success">{this.state.successMessage}</p> : <></>}

                        {this.state.errorMessage ? <p className="error">{this.state.errorMessage}</p> : <></>}
                    </form>
                </div>
            </Modal>
        );
    }
}

export default withTranslation(['translation'])(ModalForgetPassword);
