import React from 'react';
import ReactGA from 'react-ga4';
import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useTranslation } from 'react-i18next';
import 'moment/min/locales';

import Routes from './Routes';
import { lang } from './utils/utils';
import './App.css';

function App() {
    const { i18n } = useTranslation();

    ReactGA.initialize([
        {
            trackingId: 'G-MQZSY3KBPG',
            gaOptions: { name: 'tracker' },
        },
        { trackingId: 'G-VJFB0JKS7L' }
    ]);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={lang[i18n.language]}>
            <HelmetProvider>
                <Routes />
            </HelmetProvider>
        </LocalizationProvider>
    );
}

export default App;
