import React from 'react';
import moment from 'moment';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { withTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import DateInputStyles from '../../components/TextInput/DateInputStyles';
import Modal from '../../templates/Modal/Modal';
import { createUser } from '../../api/users';
import { sendConfirmationEmail } from '../../api/emails';
import { Roles } from '../../utils/utils';
import { validateForm } from '../../utils/regex';

import VisibilityIcon from '@mui/icons-material/Visibility';
const eye = <VisibilityIcon fontSize="medium" />;

const FormDefault = {
    nameT: '',
    usernameT: '',
    emailT: '',
    countryT: '',
    cityT: '',
    passwordT: '',
    roleT: Roles.teacher,
    institution: 'Cultural Structure or Association',
    telephone: '',
    birthDate: undefined,
    questionT: '',
    answerT: '',
    asnwerConfirmationT: '',
    successMessage: '',
    errorMessage: '',
    passwordShown: false,
};

class SignUpClass extends React.Component {
    constructor() {
        super();
        this.state = FormDefault;
        this.handleChangeRole = this.handleChangeRole.bind(this);
        this.handleChangeInstitution = this.handleChangeInstitution.bind(this);
    }

    onChange = (e) => {
        if (e.target.name === 'usernameT' || e.target.name === 'emailT') {
            this.setState({ [e.target.name]: e.target.value.trim() });
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
    };

    handleChangeRole(event) {
        this.setState({ roleT: event.target.value });
    }

    handleChangeInstitution(event) {
        this.setState({ institution: event.target.value });
    }

    handleRegisterT = async (e) => {
        e.preventDefault();

        this.setState({
            successMessage: this.props.t('SignInUp.Registering') + ' ...',
            errorMessage: '',
        });

        const { error, message } = validateForm(this.state.usernameT, this.state.passwordT);

        if (error) {
            this.setState({
                successMessage: '',
                errorMessage: this.props.t(message),
            });
            return;
        }

        const data = {
            username: this.state.usernameT,
            email: this.state.emailT,
            password: this.state.passwordT,
            confirmed: false,
            name: this.state.nameT,
            country: this.state.countryT,
            city: this.state.cityT,
            userRole: this.state.roleT,
            telephone: this.state.telephone,
            birthDate: moment(this.state.birthDate).format('YYYY-MM-DD'),
            institution: this.state.institution,
            securityQuestion: this.state.questionT,
            securityAnswer: this.state.answerT,
        };

        try {
            await createUser(data);
        } catch (error) {
            const errorData = error?.data;
            const errorMessage = errorData && errorData.length > 0 ? errorData[0]?.messages : null;
            const message = errorMessage && errorMessage.length > 0 ? errorMessage[0] : null;
            let errorMsg = this.props.t('MyOrders.ErrorOcurred');

            switch (message?.id) {
                case 'Auth.form.error.email.taken':
                    errorMsg = this.props.t('SignInUp.EmailTaken');
                    break;
                case 'Auth.form.error.username.taken':
                    errorMsg = this.props.t('SignInUp.UsernameTaken');
                    break;
                default:
                    errorMsg = message ? message.message : this.props.t('MyOrders.ErrorOcurred');
            }

            // Handle error.
            this.setState({
                errorMessage: errorMsg,
                successMessage: '',
            });
            return;
        }

        // Handle success.
        this.setState({
            successMessage: this.props.t('SignInUp.ConfirmingAccount'),
            errorMessage: '',
        });

        const emailData = {
            name: this.state.nameT,
            username: this.state.usernameT,
            country: this.state.countryT,
            role: this.state.roleT,
        };

        try {
            await sendConfirmationEmail(emailData);
        } catch (error) {
            console.error('An error occurred: ', error);
            return;
        }
    };

    togglePasswordVisiblity = () => {
        this.setState({ passwordShown: !this.state.passwordShown });
    };

    selectCountryT(val) {
        this.setState({ countryT: val });
    }

    selectCityT(val) {
        this.setState({ cityT: val });
    }

    handleCloseModal(closeModal) {
        this.setState(FormDefault);
        closeModal();
    }

    render() {
        const { t, open, closeModal } = this.props;
        const {
            nameT,
            usernameT,
            emailT,
            countryT,
            cityT,
            passwordT,
            birthDate,
            telephone,
            questionT,
            answerT,
            passwordShown,
        } = this.state;

        return (
            <Modal
                id="ct"
                titleClass="signInModalTitle h4Text"
                title={t('SignInUp.TeacherRegistration')}
                closeTranslation={'SignInUp.Close'}
                open={open}
                closeModal={() => this.handleCloseModal(closeModal)}
            >
                <div className="modalBody">
                    <form onSubmit={this.handleRegisterT}>
                        <div className="registerInputsWrapper">
                            <div className="authColumn">
                                <input
                                    className="inputRegister"
                                    type="text"
                                    name="nameT"
                                    placeholder={t('SignInUp.RegisterName')}
                                    value={nameT}
                                    onChange={this.onChange}
                                    required={true}
                                />
                                <div className="fieldsDivision" />

                                <input
                                    className="inputRegister"
                                    type="text"
                                    name="usernameT"
                                    placeholder={t('SignInUp.RegisterUsername')}
                                    value={usernameT}
                                    onChange={this.onChange}
                                    required={true}
                                />
                                <div className="fieldsDivision" />

                                <input
                                    className="inputRegister"
                                    type="email"
                                    name="emailT"
                                    placeholder={t('SignInUp.RegisterEmail')}
                                    value={emailT}
                                    onChange={this.onChange}
                                    required={true}
                                />
                                <div className="fieldsDivision" />

                                <input
                                    className="inputRegister"
                                    type="tel"
                                    name="telephone"
                                    placeholder={t('SignInUp.RegisterTelephone')}
                                    value={telephone}
                                    onChange={this.onChange}
                                    required={true}
                                />
                                <div className="fieldsDivision" />

                                <DatePicker
                                    label={t('SignInUp.RegisterBirthDate')}
                                    value={birthDate}
                                    onChange={(newValue) => this.setState({ birthDate: newValue })}
                                    maxDate={moment()}
                                    slotProps={{
                                        textField: {
                                            variant: 'standard',
                                            fullWidth: true,
                                            required: true,
                                            sx: DateInputStyles,
                                        },
                                    }}
                                />

                                <CountryDropdown
                                    className="dropdownRegister"
                                    value={countryT}
                                    required={true}
                                    onChange={(val) => this.selectCountryT(val)}
                                    defaultOptionLabel={t('SignInUp.SelectCountry')}
                                />
                                <div className="fieldsDivision" />

                                <RegionDropdown
                                    className="dropdownRegister"
                                    country={countryT}
                                    required={true}
                                    value={cityT}
                                    onChange={(val) => this.selectCityT(val)}
                                    defaultOptionLabel={t('SignInUp.SelectRegion')}
                                />
                                <div className="fieldsDivision" />
                            </div>

                            <div className="authColumn">
                                <select
                                    className="dropdownRegister"
                                    value={this.state.roleT}
                                    onChange={this.handleChangeRole}
                                >
                                    <option value={Roles.teacher}>{t('SignInUp.TeacherEducatorTutor')}</option>
                                    <option value={Roles.training}>{t('SignInUp.TTraining')}</option>
                                    <option value={Roles.professional}>{t('SignInUp.Professional')}</option>
                                    <option value={Roles.researcher}>{t('SignInUp.ResearcherUniversity')}</option>
                                    <option value={Roles.other}>{t('SignInUp.Other')}</option>
                                </select>
                                <div className="fieldsDivision" />

                                <select
                                    className="dropdownRegister"
                                    value={this.state.institution}
                                    onChange={this.handleChangeInstitution}
                                >
                                    <option value="Cultural Structure or Association">
                                        {t('SignInUp.CulturalAssociation')}
                                    </option>
                                    <option value="Internal Association">{t('SignInUp.InternalAssociation')}</option>
                                    <option value="University">{t('SignInUp.University')}</option>
                                    <option value="School">{t('SignInUp.School')}</option>
                                    <option value="Cinematheque">{t('SignInUp.Cinematheque')}</option>
                                    <option value="Cinema">{t('SignInUp.Cinema')}</option>
                                    <option value="Other">{t('SignInUp.Other')}</option>
                                </select>
                                <div className="fieldsDivision" />

                                <input
                                    className="inputRegister"
                                    type="text"
                                    name="questionT"
                                    placeholder={t('SignInUp.SecurityQuestion')}
                                    value={questionT}
                                    onChange={this.onChange}
                                    required={true}
                                />
                                <div className="fieldsDivision" />

                                <input
                                    className="inputRegister"
                                    type="text"
                                    name="answerT"
                                    placeholder={t('SignInUp.SecurityAnswer')}
                                    value={answerT}
                                    onChange={this.onChange}
                                    required={true}
                                />
                                <div className="fieldsDivision" />

                                <div className="passwordRegisterWrappper">
                                    <input
                                        className="inputRegister"
                                        type={passwordShown ? 'text' : 'password'}
                                        name="passwordT"
                                        value={passwordT}
                                        placeholder={t('SignInUp.RegisterPassword')}
                                        onChange={this.onChange}
                                        required={true}
                                    />
                                    <i className="eyeSignUp" onClick={this.togglePasswordVisiblity}>
                                        {eye}
                                    </i>
                                    <div className="fieldsDivision"></div>
                                </div>
                            </div>
                        </div>

                        {this.state.errorMessage ? <p className="error">{this.state.errorMessage}</p> : <></>}

                        {this.state.successMessage ? <p className="success">{this.state.successMessage}</p> : <></>}

                        <button type="submit" className="submitRegistration detailsText">
                            {t('SignInUp.Register')}
                        </button>
                    </form>
                </div>
            </Modal>
        );
    }
}

export default withTranslation(['translation'])(SignUpClass);
