import React, { useState } from 'react';
import Image from './ReactImageEnlarger';

export default function ImageEnlarger({ src, draggable }) {
    const [zoomed, setZoomed] = useState(false);

    return (
        <Image
            style={{ width: "200px", height: "auto" }}
            zoomed={zoomed}
            src={src}
            onClick={() => setZoomed(true)}
            onRequestClose={() => setZoomed(false)}
            draggable={draggable === false ? false : true}
        />
    );
}