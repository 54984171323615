import React, { useEffect } from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';

import './Popup.css';

export default function Popup(props) {
    const { t } = useTranslation();
    const { open, closeModal, children, maxWidth, fullWidth, isMRmodalOpen, setIsMRmodalOpen } = props;
    

    return (
        <Dialog 
            open={open} 
            maxWidth={maxWidth ? maxWidth : 'lg'} 
            fullWidth={fullWidth ? true : false}
        >
            <div className={isMRmodalOpen ? 'MRmodalContent' : 'modalContentSS'}>
                <div className={isMRmodalOpen ? 'MRmodalBody' : 'modalBody'}>
                    {children}
                </div>

                <div className="modalCloseBtn">
                    {isMRmodalOpen ? (
                        <div className="centerButton">
                            <button
                                type="button"
                                className="btn btn-default"
                                onClick={() => setIsMRmodalOpen(false)}
                            >
                                <p>{t('AboutTheProject.ModalCloseButton')}</p>
                            </button>
                        </div>
                    ) : (
                        <div className="centerButton">
                            <button
                                type="button"
                                className="btn btn-default"
                                onClick={closeModal}
                            >
                                <p>{t('AboutTheProject.ModalCloseButton')}</p>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </Dialog>
    );
}
