import { apiInfo, fetchRequest, HTTPMethod, Resource } from "./api";

export const getOrdersByUserId = () => {
  return fetchRequest(
    HTTPMethod.GET,
    `${apiInfo.base}/${Resource.ORDERS}/list`,
    null,
    true
  );
};
