import React from 'react';
import i18next from 'i18next';
import { NavLink, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Body from '../../../templates/Body/Body';
import BodyExercise from '../../BodyExercise/BodyExercise';
import ExerciseIcons from '../../BodyExercise/ExerciseIcons';
import Loading from '../../../components/Loading/Loading';
import { BASE_URL } from '../../../utils/globals';
import { uploadMultipleImages } from '../../../api/upload';
import { updateStudentSheetAnswer } from '../../../api/student-sheets';
import { getInteractiveStudentSheet } from '../../../utils/ssUtils';
import { DescriptionTranslations, getExerciseDescription } from '../../../utils/ssUtils';

import icon from '../../../assets/images/student-sheets/uploadActivity.svg';
import './ExC22.css';

const ImagesNo = [1, 2];

class ExC22Class extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ex: null,
            loading: true,
            imgData1: null,
            img1: null,
            imgData2: null,
            img2: null,
            uploadImage1: true,
            uploadImage2: true,
            viewMode: false
        };
        this.onImageChange = this.onImageChange.bind(this);
        this.onImageRemove = this.onImageRemove.bind(this);
    }

    componentDidMount = async () => {
        localStorage.setItem('lastPage', '/exC22');
        const ssId = this.props.match.params.id;
        const user = JSON.parse(localStorage.getItem('user'));

        let answer, exercise;
        try {
            const response = await getInteractiveStudentSheet(ssId, user.id);
            exercise = response.exercise;
            answer = response.answer;
        } catch (error) {
            console.error('An error occurred: ', error);
            this.setState({ error: true });
            return;
        }

        const disableText = localStorage.getItem('disable');
        this.updateExercise(answer, disableText);

        this.setState({
            loading: false,
            ex: exercise,
            answerId: answer?.id
        });
    }

    updateExercise = (answer, disabled) => {
        const answer1 = answer.ExerciseC2_2_Image1;
        const answer2 = answer.ExerciseC2_2_Image2;

        const img1 = answer1?.length > 0 ? `${BASE_URL}${answer1[0].url}` : null;
        const img2 = answer2?.length > 0 ? `${BASE_URL}${answer2[0].url}` : null;

        if (disabled) {
            this.setState({
                img1: img1,
                img2: img2,
                viewMode: true
            });
        } else {
            this.setState({
                img1: img1,
                img2: img2,
                uploadImage1: img1 ? false : true,
                uploadImage2: img2 ? false : true,
            });

            localStorage.setItem('code', answer?.Code);
        }
    }

    onImageChange = (event, imgNo) => {
        if (event.target.files && event.target.files[0]) {
            const img = event.target.files[0];

            if (event.target.files[0].size > 2000000) {
                alert(this.props.t('StudentSheetsContent.FileBig'))
            } else if (event.target.files[0].type !== "image/png" && event.target.files[0].type !== "image/jpeg") {
                alert(this.props.t('StudentSheetsContent.FileImage'))
            } else {
                const i1 = event.target.files[0];
                this.setState({
                    [`imgData${imgNo}`]: img,
                    [`img${imgNo}`]: URL.createObjectURL(i1),
                    [`uploadImage${imgNo}`]: false
                });
                event.target.value = '';
            }
        }
    };

    onImageRemove = (event, imgNo) => {
        if (!event.target.files) {
            this.setState({
                [`imgData${imgNo}`]: null,
                [`img${imgNo}`]: null,
                [`uploadImage${imgNo}`]: true
            });
        }
    };

    submitHandler = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        const img1 = this.state.imgData1;
        const img2 = this.state.imgData2;
        const hasImages = img1 || img2;

        let files = [];
        let imgIds = {};
        let imgId = 0;
        if (img1) {
            files.push(img1);
            imgIds.imgData1 = imgId;
            imgId++;
        }
        if (img2) {
            files.push(img2);
            imgIds.imgData2 = imgId;
            imgId++;
        }

        if (files.length === 0) {
            if (!hasImages) { alert('No images selected!'); }
            this.setState({ loading: false });
            return;
        }

        let images;
        try {
            images = await uploadMultipleImages(files);
        } catch (error) {
            console.error('An error occurred: ', error);
            this.setState({ error: true });
            return;
        }

        let answerExercise = {};
        if (imgIds.imgData1 !== undefined) {
            answerExercise.ExerciseC2_2_Image1 = images[imgIds.imgData1].id;
        }
        if (imgIds.imgData2 !== undefined) {
            answerExercise.ExerciseC2_2_Image2 = images[imgIds.imgData2].id;
        }

        try {
            await updateStudentSheetAnswer(this.state.answerId, answerExercise);
        } catch (error) {
            console.error('An error occurred: ', error);
            this.setState({ error: true });
            return;
        }

        this.setState({ loading: false });
    };

    resetExercise = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        const answerExercise = {
            ExerciseC2_2_Image1: null,
            ExerciseC2_2_Image2: null
        };

        try {
            await updateStudentSheetAnswer(this.state.answerId, answerExercise);
        } catch (error) {
            console.error('An error occurred: ', error);
            this.setState({ error: true });
            return;
        }

        this.setState({
            imgData1: null,
            img1: null,
            imgData2: null,
            img2: null,
            uploadImage1: true,
            uploadImage2: true,
            loading: false
        });
    };

    render() {
        const { t } = this.props;

        const checkToken = localStorage.getItem('token');
        if (!checkToken) { return <Redirect to="/noAccount" />; }

        if (this.state.loading) {
            return (
                <Body
                    bodyClass="content"
                    title="C2.2 Exercise | CinEd"
                >
                    <Loading />
                </Body>
            );
        }

        const exercise = this.state.ex;
        const description = getExerciseDescription(this.state.ex, 'C2_2', i18next.language, DescriptionTranslations);

        return (
            <BodyExercise
                selectedOption='exC21'
                studentSheetId={this.props.match.params.id}
                movieTitle={exercise?.MovieTitle}
                pageTitle={`C2.2 Exercise | ${exercise?.MovieTitle} | CinEd`}
                viewMode={this.state.viewMode}
                interactiveEx={true}
                prevLink={`/exC11/${this.props.match.params.id}`}
                nextLink={`/exD/${this.props.match.params.id}`}
            >
                <ExerciseIcons
                    studentSheetId={this.props.match.params.id}
                    exIcon={icon}
                    exTitle='StudentSheetsLeftMenu.ChoosingCreatingImages'
                    exDescription={description}
                    viewMode={this.state.viewMode}
                    resetExercise={this.resetExercise}
                    submitHandler={this.submitHandler}
                    activeReset
                    activeSubmit
                />

                <div className="exerciseContainer exC22">
                    {ImagesNo.map((idx) =>
                        <div key={idx} className="imagesWrapper exC22">
                            <img
                                className="imageExerciseC22"
                                src={`${BASE_URL}${exercise.C2_2[`Image${idx}`].url}`}
                                alt={`Original Photogram ${idx}`}
                            />

                            <div className="uploadFileBox exC22">
                                <img
                                    className="imageUploadedEx22"
                                    src={this.state[`img${idx}`]}
                                    alt={`My photogram ${idx}`}
                                />

                                {this.state[`uploadImage${idx}`] && !this.state.viewMode ? <div>
                                    <input
                                        id={`file${idx}`}
                                        className="inputfile"
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        name={`file${idx}`}
                                        onChange={(ev) => this.onImageChange(ev, idx)}
                                    />

                                    <label htmlFor={`file${idx}`} className="imgButton">
                                        {t('StudentSheetsContent.Photogram')}
                                    </label>
                                </div> : <></>}

                                {this.state[`uploadImage${idx}`] || this.state.viewMode ? <></> : <div className="changeContainer">
                                    <button className="imgButton btnMaxWidth" onClick={(ev) => this.onImageRemove(ev, idx)}>
                                        {t('StudentSheetsContent.ChangeRemove')}
                                    </button>
                                </div>}
                            </div>
                        </div>)}
                </div>

                <div className="exerciseButtonsGroup">
                    <div className="buttonsGroupWrapper">
                        <div>
                            <NavLink to={`/exC21/${this.props.match.params.id}`}>
                                <button className="exerciseButtonNotSelected">1</button>
                            </NavLink>
                        </div>
                        <div>
                            <NavLink to={`/exC22/${this.props.match.params.id}`}>
                                <button className="exerciseButtonSelected">2</button>
                            </NavLink>
                        </div>
                        <div>
                            <NavLink to={`/exC23/${this.props.match.params.id}`}>
                                <button className="exerciseButtonNotSelected">3</button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </BodyExercise>
        )
    }

}

export default withTranslation(['translation'])(ExC22Class);