import i18next from 'i18next';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { BASE_URL } from '../../utils/globals';
import { getMovieImageURL, getTranslation } from '../../utils/utils';
import './VideoCard.css';

const Prefix = 'translations.';
const DefaultTranslation = Prefix + 'english';

class VideoCard extends React.Component {
    getMovieTranslation(entry) {
        return getTranslation(entry, i18next.language, Prefix, DefaultTranslation);
    }

    render() {
        const { movie, isMovie } = this.props;

        const photoKey = isMovie ? 'CollectionPhotogram' : 'FavoritePhotogram';
        const videoResource = isMovie ? 'movies' : 'pedagogical-videos';

        return (
            <div className="alignMovieCaption" key={movie?.id}>

                <div className="centeredContainer">
                    <div className="videoCardContainer">
                        <Link to={`/${videoResource}/${movie?.id}`}>
                            <img
                                className="videoPhotogram"
                                src={`${BASE_URL}${getMovieImageURL(movie, photoKey)}`}
                                alt="Collection Photogram"
                            />
                        </Link>

                        {isMovie ? <div className="dot">
                            <p className="age normalText">+{movie?.Age}</p>
                        </div> : <></>}
                    </div>
                </div>
                <div className="movieTitleContainer">
                    <Link className="captionMovie normalText" to={`/${videoResource}/${movie?.id}`}>
                        {movie?.OriginalTitle} [+]
                    </Link>

                    <p className="translatedTitle">
                        {this.getMovieTranslation(movie)?.TitleTranslation}
                    </p>
                </div>
            </div>
        )
    }
}

export default withTranslation(['translation'])(VideoCard);