import React from 'react';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import moment from 'moment';

import MetaTags from '../../components/MetaTags/MetaTags';
import NavBarClass from '../../templates/NavBar/NavBar';
import ProfileForm from '../../components/ProfileForm/ProfileForm';
import { getUserByMigrationToken, updateUserByToken } from '../../api/users';
import { Roles } from '../../utils/utils';
import { validateForm } from '../../utils/regex';

import background from '../../assets/images/aniki-bobo.webp';
import './Migration.css';

class Migration extends React.Component {
    constructor() {
        super();
        this.state = {
            userId: null,
            name: '',
            username: '',
            email: '',
            country: '',
            city: '',
            password: '',
            role: Roles.teacher,
            institution: 'Cultural Structure or Association',
            school: '',
            telephone: '',
            birthDate: undefined,
            question: '',
            answer: '',
            successMessage: '',
            errorMessage: '',
            passwordShown: false,
            disabled: false,
            loading: true,
            isTeacher: true,
        };
        this.selectCity = this.selectCity.bind(this);
        this.selectCountry = this.selectCountry.bind(this);
        this.togglePasswordVisiblity = this.togglePasswordVisiblity.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    componentDidMount = async () => {
        const { t } = this.props;

        let res;
        try {
            res = await getUserByMigrationToken(this.props.match.params.id);
        } catch (error) {
            toast.error(t('MyOrders.ErrorOcurred'));
            console.error('An error occurred: ', error);
            this.setState({ loading: false, disabled: true, errorMessage: t('MyOrders.ErrorOcurred') });
            return;
        }

        if (!res || res.length === 0) {
            toast.error(t('MyOrders.ErrorOcurred'));
            this.setState({ loading: false, disabled: true, errorMessage: t('MyOrders.ErrorOcurred') });
            return;
        }

        const user = res[0];
        const isTeacher = this.props.match.params.type === 'teacher';

        this.setState({
            userId: user.id,
            name: user.name ? user.name : '',
            username: user.noUsername ? '' : user.username,
            email: user.email ? user.email : '',
            country: user.country ? user.country : '',
            city: user.city ? user.city : '',
            role: user.userRole ? user.userRole : this.state.role,
            institution: user.institution ? user.institution : this.state.institution,
            school: user.school ? user.school : '',
            telephone: user.telephone ? user.telephone : '',
            birthDate: user.birthDate ? moment(user.birthDate) : undefined,
            question: user.securityQuestion ? user.securityQuestion : '',
            answer: user.securityAnswer ? user.securityAnswer : '',
            isTeacher: isTeacher,
            loading: false,
        });

        if (isTeacher && user.userRole === Roles.student) {
            toast.error(t('MyOrders.ErrorOcurred'));
            this.setState({
                loading: false,
                disabled: true,
                isTeacher: false,
                errorMessage: t('MyOrders.ErrorOcurred'),
            });
        }
    };

    onChange = (e) => {
        if (e.target.name === 'username' || e.target.name === 'email') {
            this.setState({ [e.target.name]: e.target.value.trim() });
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
    };

    onDateChange = (val) => {
        this.setState({ birthDate: val });
    };

    selectCountry(val) {
        this.setState({ country: val });
    }

    selectCity(val) {
        this.setState({ city: val });
    }

    togglePasswordVisiblity = () => {
        this.setState({ passwordShown: !this.state.passwordShown });
    };

    async handleFormSubmit(e) {
        e.preventDefault();

        this.setState({
            successMessage: this.props.t('SignInUp.Loading') + ' ...',
            errorMessage: '',
            disabled: true,
        });

        const {
            name,
            username,
            email,
            telephone,
            birthDate,
            country,
            city,
            school,
            role,
            institution,
            question,
            answer,
            password,
            isTeacher,
        } = this.state;

        const { error, message } = validateForm(username, password);

        if (error) {
            this.setState({
                successMessage: '',
                errorMessage: this.props.t(message),
                disabled: false,
            });
            return;
        }

        let data = {
            username: username,
            email: email,
            password: password,
            name: name,
            country: country,
            city: city,
            migrationToken: null,
            securityQuestion: question,
            securityAnswer: answer,
            confirmed: true,
            noUsername: false,
        };

        if (isTeacher) {
            data = {
                ...data,
                telephone: telephone,
                birthDate: moment(birthDate).format('YYYY-MM-DD'),
                institution: institution,
                userRole: role,
            };
        } else {
            data = {
                ...data,
                school: school,
                userRole: Roles.student,
            };
        }

        let res;
        try {
            res = await updateUserByToken(this.props.match.params.id, data);
        } catch (error) {
            const errorData = error?.data;
            const errorMessage = errorData && errorData.length > 0 ? errorData[0]?.messages : null;
            const message = errorMessage && errorMessage.length > 0 ? errorMessage[0] : null;
            let errorMsg = this.props.t('MyOrders.ErrorOcurred');

            switch (message?.id) {
                case 'Auth.form.error.email.taken':
                    errorMsg = this.props.t('SignInUp.EmailTaken');
                    break;
                case 'Auth.form.error.username.taken':
                    errorMsg = this.props.t('SignInUp.UsernameTaken');
                    break;
                default:
                    errorMsg = message ? message.message : this.props.t('MyOrders.ErrorOcurred');
            }

            // Handle error.
            this.setState({
                errorMessage: errorMsg,
                successMessage: '',
                disabled: false,
            });
            return;
        }

        if (res) {
            localStorage.setItem('user', JSON.stringify(res, null, 2));
        }

        // Handle success.
        this.setState({
            successMessage: this.props.t('Player.AccountUpdated'),
            errorMessage: '',
            disabled: true,
        });
    }

    render() {
        const { t } = this.props;
        const {
            name,
            username,
            email,
            telephone,
            birthDate,
            country,
            city,
            role,
            institution,
            school,
            question,
            answer,
            password,
            isTeacher,
            passwordShown,
            loading,
            disabled,
            errorMessage,
            successMessage,
        } = this.state;

        return (
            <div className="App" style={{ backgroundImage: `url(${background})` }}>
                <MetaTags title={`${t('Player.UpdateAccount')} | CinEd`} />
                <NavBarClass />

                <ProfileForm
                    title={t('Player.MigrationTitle')}
                    desc1={t('Player.MigrationDescPart1')}
                    desc2={t('Player.MigrationDescPart2')}
                    name={name}
                    username={username}
                    email={email}
                    telephone={telephone}
                    birthDate={birthDate}
                    country={country}
                    city={city}
                    role={role}
                    institution={institution}
                    school={school}
                    question={question}
                    answer={answer}
                    password={password}
                    isTeacher={isTeacher}
                    passwordShown={passwordShown}
                    loading={loading}
                    disabled={disabled}
                    errorMessage={errorMessage}
                    successMessage={successMessage}
                    myProfile={false}
                    handleFormSubmit={this.handleFormSubmit}
                    onChange={this.onChange}
                    onDateChange={this.onDateChange}
                    selectCountry={this.selectCountry}
                    selectCity={this.selectCity}
                    togglePasswordVisiblity={this.togglePasswordVisiblity}
                />

                <div className="signInUpPlaceholder" />
            </div>
        );
    }
}

export default withTranslation(['translation'])(Migration);
