export const validateForm = (username, password) => {
    // Validate username length
    if (username && (username.length < 5 || username.length > 20)) {
        return {
            error: true,
            message: 'SignInUp.InvalidUsernameLength',
        };
    }

    // Validate username characters
    const validCharacters = /^[^\s]+$/u;
    if (username && !validCharacters.test(username)) {
        return {
            error: true,
            message: 'SignInUp.InvalidUsername',
        };
    }

    // Validate password length
    if (password && (password.length < 8 || password.length > 16)) {
        return {
            error: true,
            message: 'SignInUp.InvalidPasswordLength',
        };
    }

    return { error: false, message: 'Success' };
};
