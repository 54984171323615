export const tableContainer = {
    marginTop: '25px',
    borderRadius: '10px',
    boxShadow: 'none',
    border: '1px solid #D7D7D7'
};

export const tableHead = {
    '& .MuiTableCell-head': {
        color: '#00868E',
        fontSize: '18px',
        fontWeight: 800
    },
    '& .MuiTableRow-root > *:not(:last-child)': {
        borderRight: '1px solid #D7D7D7'
    }
};

export const tableBody = {
    '& .MuiTableCell-body': {
        color: '#000000',
        fontSize: '18px'
    },
    '& .MuiTableRow-root > *:not(:last-child)': {
        borderRight: '1px solid #D7D7D7'
    }
};