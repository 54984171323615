import React from 'react';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

import Body from '../../templates/Body/Body';
import Loading from '../../components/Loading/Loading';
import Modal from '../../templates/Modal/Modal';
import MovieHeader from '../../components/MoviesPage/MovieHeader';
import MoviePhotoVideos from '../../components/MoviesPage/MoviePhotoVideos';
import RelatedMovies from './RelatedMovies';
import TechnicalData from '../../components/TechnicalData/TechnicalData';
import { Translations } from '../../utils/utils';
import { getMovieById } from '../../api/movies';
import { isMovieOrdered } from '../../api/movie-orders';
import { refreshSession } from '../../api/users';
import { getPhotogramsById } from '../../api/photograms';
import { getLanguageInitials } from '../../utils/utils';
import { removeSSFromStorage } from '../../utils/ssUtils';

import './Movie.css';

const TechnicalDataKeys = [
    'Color', 'Type', 'Director', 'ScreenPlay', 'Cinematographer',
    'Decoration', 'Costume', 'Sound', 'Editing', 'CoProducer'
];

class MovieClass extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: false,
            loading: true,
            isUserLoggedIn: false,
            isUserTeacher: false,
            movie: null,
            isOrdered: false,
            studentSheetPDF: '',
            educationalFile: '',
            photogramsUrl: null,
            langInitials: 'en',
            selected: null
        };

        this.checkIfMovieIsOrdered = this.checkIfMovieIsOrdered.bind(this);
        this.getMovieDetails = this.getMovieDetails.bind(this);
        this.updateLanguageInitials = this.updateLanguageInitials.bind(this);
        this.updateMovieFiles = this.updateMovieFiles.bind(this);
        this.setSelectedModal = this.setSelectedModal.bind(this);
    }

    async componentDidMount() {
        const movieId = this.props.match.params.id;
        const token = localStorage.getItem('token');

        removeSSFromStorage();

        this.updateLanguageInitials(i18next.language);

        let promises = [
            this.getMovieDetails(movieId, token)
        ];

        if (token) {
            promises.push(this.checkIfMovieIsOrdered(movieId));
        }

        try {
            await Promise.all(promises);
        } catch (error) {
            console.error('An error occurred: ', error);
        }

        this.setState({
            loading: false
        });
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.t !== prevProps.t) {
            this.updateLanguageInitials(i18next.language);

            let movieDetails = this.state.movie;
            if (!movieDetails) return;

            const studentSheetsPDF = movieDetails.student_sheets_pdf;
            const educationalFiles = movieDetails.educational_files;

            this.updateMovieFiles(i18next.language, studentSheetsPDF, educationalFiles);
        }

        if (!this.state.loading && !this.state.error && (this.state.movie?.id !== this.props.match.params.id)) {
            this.setState({
                loading: true
            });

            const movieId = this.props.match.params.id;
            const token = localStorage.getItem('token');

            let promises = [
                this.getMovieDetails(movieId, token)
            ];

            if (token) {
                promises.push(this.checkIfMovieIsOrdered(movieId));
            }

            try {
                await Promise.all(promises);
            } catch (error) {
                console.error('An error occurred: ', error);
                this.setState({ error: true });
            }

            this.setState({
                loading: false
            });
        }
    }

    async getMovieDetails(movieId) {
        let res;
        let promises = [
            getMovieById(movieId),
            getPhotogramsById(movieId)
        ];

        try {
            res = await Promise.all(promises);
        } catch (error) {
            console.error('An error occurred: ', error);
            this.setState({ error: true });
            return;
        }

        const movie = res[0];
        const photogramsList = res[1];

        const photogramItem = photogramsList?.length > 0 ? photogramsList[0] : null;
        const photogram = photogramItem?.ImageSet?.length > 0 ? photogramItem?.ImageSet[0] : null;

        this.setState({
            photogramsUrl: photogram?.url,
            movie: movie
        });

        const studentSheetsPDF = movie.student_sheets_pdf;
        const educationalFiles = movie.educational_files;
        this.updateMovieFiles(i18next.language, studentSheetsPDF, educationalFiles);
    }

    async checkIfMovieIsOrdered(movieId) {
        let user;
        try {
            user = await refreshSession();
        } catch (error) {
            console.error('An error occurred: ', error);
            return;
        }

        let res;
        try {
            res = await isMovieOrdered(movieId, 'movie');
        } catch (error) {
            console.error('An error occurred: ', error);
            return;
        }

        const isOrdered = res.result === true ? true : false;

        this.setState({
            isUserLoggedIn: true,
            isUserTeacher: user?.userRole !== 'Student' && user.customerId ? true : false,
            isOrdered: isOrdered
        });
    }

    updateLanguageInitials(lang) {
        const languageInitials = getLanguageInitials(lang);
        this.setState({ langInitials: languageInitials });
    }

    updateMovieFiles(lang, studentSheetsPDF, educationalFiles) {
        let ssPDF;
        let edFile;

        let language = Translations[lang] ? Translations[lang] : '';
        if (lang === 'DE') { language = 'German'; }
        else if (language) {
            language = language.charAt(0).toUpperCase() + language.slice(1);
        }

        if (studentSheetsPDF) {
            if (studentSheetsPDF[language]) {
                ssPDF = studentSheetsPDF[language].url;
            } else {
                ssPDF = studentSheetsPDF?.English?.url;
            }
        }

        if (educationalFiles) {
            if (educationalFiles[language]) {
                edFile = educationalFiles[language].url;
            } else {
                edFile = educationalFiles?.English?.url;
            }
        }

        this.setState({
            studentSheetPDF: ssPDF,
            educationalFile: edFile
        });
    }

    updateIsOrdered(isOrdered) {
        this.setState({
            isOrdered: isOrdered
        });
    }

    setSelectedModal(selected) {
        this.setState({
            selected: selected
        });
    }

    render() {
        const { t } = this.props;
        const {
            movie, langInitials, isOrdered, selected,
            loading, isUserLoggedIn, isUserTeacher,
            studentSheetPDF, educationalFile, photogramsUrl
        } = this.state;

        if (loading) {
            return (
                <Body
                    bodyClass="moviePage"
                    title={`${t('NavBar.Movies')} | CinEd`}
                >
                    <Loading />;
                </Body>
            );
        }

        const ac = movie?.Actors;

        return (
            <Body
                bodyClass="moviePage"
                title={`${movie?.OriginalTitle} | CinEd`}
            >
                <MovieHeader
                    movie={movie}
                    studentSheetPDF={studentSheetPDF}
                    educationalFile={educationalFile}
                    photogramsUrl={photogramsUrl}
                    langInitials={langInitials}
                    isUserLoggedIn={isUserLoggedIn}
                    isUserTeacher={isUserTeacher}
                    isMovie={true}
                    isOrdered={isOrdered}
                    updateIsOrdered={this.updateIsOrdered.bind(this)}
                    setSelectedModal={(selected) => this.setSelectedModal(selected)}
                />

                <div className="movieDetails container">
                    <MoviePhotoVideos
                        photoGallery={movie?.PhotoGallery?.Photo}
                        videoGallery={movie?.VideoGallery?.Video}
                    />

                    <RelatedMovies currentMovieId={movie?.id} />
                </div>

                <Modal
                    id="td"
                    titleClass="modalTitleTD h3Text"
                    title={t('Movie.TechnicalData')}
                    closeTranslation={'Movie.Close'}
                    open={selected === 'td' ? true : false}
                    closeModal={() => this.setSelectedModal(null)}
                >
                    <TechnicalData
                        movie={movie}
                        technicaldata={TechnicalDataKeys}
                    />
                </Modal>

                <Modal
                    id="ac"
                    titleClass="modalTitleTD h3Text"
                    title={t('Movie.Actors')}
                    closeTranslation={'Movie.Close'}
                    open={selected === 'ac' ? true : false}
                    closeModal={() => this.setSelectedModal(null)}
                >
                    {ac?.map((actor, index) =>
                        <div key={index}>
                            <div className="modalSection">
                                <div className="widthLabel">
                                    <p className="modalSectionLabel normalText">
                                        {actor.Name}
                                    </p>
                                </div>
                                <div className="widthInfo">
                                    <p className="modalSectionInfo normalText">{actor.CharacterName}</p>
                                </div>
                            </div>
                            <div className="separatorModal" />
                        </div>
                    )}
                </Modal>

            </Body>
        )
    }
}

export default withTranslation(['translation'])(MovieClass);