import React from 'react';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import Body from '../../templates/Body/Body';
import BodyExercise from '../BodyExercise/BodyExercise';
import ExerciseIcons from '../BodyExercise/ExerciseIcons';
import Loading from '../../components/Loading/Loading';
import { BASE_URL } from '../../utils/globals';
import { getExerciseText, getExerciseDescription, DescriptionTranslations } from '../../utils/ssUtils';
import { getStudentSheetById } from '../../api/student-sheets';

import icon from '../../assets/images/student-sheets/hearActivity.svg';
import './ExA2.css';

class ExA2Class extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            ex: null,
            viewMode: false
        };
    }

    componentDidMount = async () => {
        localStorage.setItem('lastPage', '/exA2');
        const ssId = this.props.match.params.id;

        let response;
        try {
            response = await getStudentSheetById(ssId);
        } catch (error) {
            console.error('An error occurred: ', error);
            return;
        }

        this.setState({
            loading: false,
            ex: response
        });

        if (localStorage.getItem('disable')) {
            this.setState({ viewMode: true });
        }
    }
    
    render() {
        const checkToken = localStorage.getItem('token');
        if (!checkToken) { return <Redirect to="/noAccount" />; }

        if (this.state.loading) {
            return (
                <Body
                    bodyClass="content"
                    title="A2 Exercise | CinEd"
                >
                    <Loading />
                </Body>
            );
        }

        const exercise = this.state.ex;
        const exerciseDetails = exercise?.A2;

        const description = getExerciseDescription(this.state.ex, 'A2', i18next.language, DescriptionTranslations);

        return (
            <BodyExercise
                selectedOption='exA2'
                studentSheetId={this.props.match.params.id}
                movieTitle={exercise?.MovieTitle}
                pageTitle={`A2 Exercise | ${exercise?.MovieTitle} | CinEd`}
                viewMode={this.state.viewMode}
                interactiveEx={false}
                prevLink={`/exA1/${this.props.match.params.id}`}
                nextLink={`/exA3/${this.props.match.params.id}`}
            >
                <ExerciseIcons
                    studentSheetId={this.props.match.params.id}
                    exIcon={icon}
                    exTitle='StudentSheetsLeftMenu.HeardInFilm'
                    exDescription={description}
                    viewMode={this.state.viewMode}
                />

                <div className="exerciseA2Container">
                    <p className="ssNormalText textExerciseA2 boldText">
                        {getExerciseText(this.state.ex, 'A2', i18next.language)}
                    </p>
                    
                    <div>
                        <img className="imageExerciseA2" src={`${BASE_URL}${exerciseDetails?.Photogram?.url}`} alt="photogramSound" />
                        <div className="playRectangle">
                            <audio className="audio" controls >
                                <source id="audioTarget" type="audio/mpeg" src={`${BASE_URL}${exerciseDetails?.SoundMP3.url}`} />
                            </audio>
                        </div>
                    </div>
                </div>
            </BodyExercise>
        )
    }
}

export default withTranslation(['translation'])(ExA2Class);