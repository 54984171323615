import React from 'react';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import ModalForgetPassword from './ModalForgetPassword';
import { login } from '../../api/auth';

class Login extends React.Component {
    constructor() {
        super();

        this.state = {
            username: '',
            password: '',
            errorMessage: '',
            successMessage: '',
            openFPModal: false
        };
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleConfirmation = async (e) => {
        e.preventDefault();

        this.setState({
            successMessage: this.props.t('SignInUp.Credentials') + ' ...',
            errorMessage: ''
        });

        try {
            const response = await login(this.state.username, this.state.password);
            this.setState({ successMessage: this.props.t('SignInUp.Credentials') + " ..." });

            if (response.jwt) {
                localStorage.setItem('user', JSON.stringify(response.user, null, 2));
                localStorage.setItem('token', response.jwt);
            }

            window.location = '/collection';
        } catch (error) {
            const errorData = error?.data;
            const errorMessage = errorData && errorData.length > 0 ? errorData[0]?.messages : null;
            const message = errorMessage && errorMessage.length > 0 ? errorMessage[0] : null;
            let errorMsg = this.props.t('MyOrders.ErrorOcurred');

            switch (message?.id) {
                case 'Auth.form.error.invalid':
                    errorMsg = this.props.t('SignInUp.WrongCredentials');
                    break;
                default:
                    errorMsg = message ? message.message : this.props.t('MyOrders.ErrorOcurred');
            }

            this.setState({
                successMessage: '',
                errorMessage: errorMsg
            });
        }
    }

    render() {
        const { t, forgotPassword } = this.props;
        const { username, password, openFPModal } = this.state;

        return (
            <>
                <div className="signInFormWrapper">
                    <div className="signInStudentTeacher">
                        <p className="signInStudentTitle h2Text">
                            {t('SignInUp.Welcome')}
                        </p>

                        <form className="signInFormWrapper" onSubmit={this.handleConfirmation}>
                            <input
                                className="authInput"
                                type="text"
                                name="username"
                                placeholder={t('SignInUp.Username')}
                                value={username}
                                onChange={this.onChange}
                                required={true}
                            />
                            <div className="fieldsDivision" />

                            <input
                                className="authInput gap"
                                type="password"
                                name="password"
                                value={password}
                                placeholder={t('SignInUp.Password')}
                                onChange={this.onChange}
                                required={true}
                            />
                            <div className="fieldsDivision" />

                            {this.state.errorMessage ?
                                <p className="error">{this.state.errorMessage}</p> : <></>}

                            {this.state.successMessage ?
                                <p className="success">{this.state.successMessage}</p> : <></>}

                            <button
                                className="submitRegistration smallText"
                                type="submit"
                            >
                                {t('SignInUp.LogIn')}
                            </button>
                        </form>

                        {forgotPassword ? <button
                            className="submitRegistration smallText forgotPass"
                            onClick={() => this.setState({ openFPModal: true })}
                        >
                            {t('SignInUp.ForgotPassword')}
                        </button> : <></>}
                    </div>
                </div>

                <div>
                    <p className="registrationTitle normalText">
                        {t('SignInUp.NoAccountQuestion')}
                    </p>

                    <NavLink className="btnRegistrationLink" to="/registration">
                        <button className="btnRegistration smallText">{t('SignInUp.Register')}</button>
                    </NavLink>
                </div>

                <ModalForgetPassword
                    open={openFPModal}
                    closeModal={() => this.setState({ openFPModal: false })}
                />
            </>
        );

    }
}

export default withTranslation(['translation'])(Login);