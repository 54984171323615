import React from 'react';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

import ArrowsMain from '../BodyExercise/ArrowsMain';
import Body from '../../templates/Body/Body';
import Loading from '../../components/Loading/Loading';
import { BASE_URL } from '../../utils/globals';
import { getStudentSheetById } from '../../api/student-sheets';
import { lang } from '../../utils/utils';

import './share.css';

class Share extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ex: null,
            loading: true
        };

    }

    async componentDidMount() {
        const ssId = this.props.match.params.id;

        let response;
        try {
            response = await getStudentSheetById(ssId);
        } catch (error) {
            console.error('An error occurred: ', error);
            return;
        }

        this.setState({
            loading: false,
            ex: response
        });
    }

    render() {
        const { t } = this.props;

        const checkToken = localStorage.getItem('token');
        if (!checkToken) { return <Redirect to="/noAccount" />; }

        if (this.state.loading) {
            return (
                <Body
                    bodyClass="content"
                    title="Share Student Sheet | CinEd"
                >
                    <Loading />
                </Body>
            );
        }

        const exercise = this.state.ex;
        const ssId = this.props.match.params.id;

        return (
            <Body
                bodyClass="content"
                title={`Share Student Sheet | ${exercise?.MovieTitle} | CinEd`}
            >
                <div className="shareBoxForm">
                    <div className="shareWrapper">
                        <img 
                            className="posterSharePage" 
                            src={`${BASE_URL}${exercise?.C2_1.OriginalPoster.url}`} 
                            alt="originalPoster"
                        />
                        
                        <div className="shareTextWrapper">
                            <p 
                                className="shareText"
                                lang={lang[i18next.language]}
                            ><b>{t('ShareStudentSheet.Share')}</b></p>

                            <div className="shareCodeForm">
                                <p className="textCode">
                                    {localStorage.getItem('code')}
                                </p>
                            </div>

                            <div>
                                <p className="instructions"><b>1. </b>{t('ShareStudentSheet.First')}</p>
                                <p className="instructions"><b>2. </b>{t('ShareStudentSheet.Second')}</p>
                            </div>
                        </div>
                    </div>

                    <ArrowsMain
                        prevLink={`${localStorage.getItem('lastPage')}/${ssId}`}
                    />
                </div>
            </Body>
        )
    }
}

export default withTranslation(['translation'])(Share);