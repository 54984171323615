import React from 'react';
import i18next from 'i18next';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import Body from '../../templates/Body/Body';
import Gallery from '../../components/Gallery/Gallery';
import Loading from '../../components/Loading/Loading';
import { getEventsNewsById } from '../../api/events-and-news';
import { getTranslation, Translations } from '../../utils/utils';

import arrowLeft from '../../assets/images/about-project/leftArrow.svg';
import './NewEventDetails.css';

const Prefix = 'news-events-translations.';
const DefaultTranslation = Prefix + 'english';

class NewEventDetailsClass extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            details: [],
            loading: true
        };

        this.getTranslatedType = this.getTranslatedType.bind(this);
        this.getTranslatedTitle = this.getTranslatedTitle.bind(this);
    }

    async componentDidMount() {
        const detailsId = this.props.match.params.id;

        let details;
        try {
            details = await getEventsNewsById(detailsId);
        } catch (error) {
            console.error('An error occurred: ', error);
            return;
        }

        this.setState({
            details: details,
            loading: false
        });
    }

    getTranslatedType(type) {
        const { t } = this.props;
        let translatedType = type;

        switch (type.toLowerCase()) {
            case 'news':
                translatedType = t('NewsEvents.ButtonNews');
                break;
            case 'event':
                translatedType = t('NewsEvents.ButtonEvents');
                break;
            default:
                console.log('Type does not exist...', type);
        }

        return translatedType;
    }

    getTranslatedTitle(content) {
        const language = Prefix + Translations[i18next.language];
        const translation = content?.Translations.find(x => x.__component === language);

        if (translation && translation.Title) {
            console.log(content, translation.Title)

            return translation.Title;
        } else return content?.Title;
    }

    render() {
        const { t } = this.props;
        
        if (this.state.loading) {
            return (
                <Body
                    bodyClass="content"
                    title={`${t('NavBar.EventsNews')} | CinEd`}
                >
                    <Loading />
                </Body>
            );
        }

        const info = this.state.details;
        const gallery = info.Gallery;
        const newsTranslation = getTranslation(info, i18next.language, Prefix, DefaultTranslation);

        return (
            <Body
                bodyClass="content"
                title={`${info.Title} | CinEd`}
            >
                <div className="headerWrapperNEDetails">
                    <div className="backNEWrapper">
                        <NavLink to={'/news-events'}>
                            <img 
                                className="backArrowNews" 
                                src={arrowLeft}
                                alt="Back Arrow" 
                            />
                        </NavLink>

                        <NavLink className="backNEText" to={'/news-events'}>
                            {t('NewsEvents.Back')}
                        </NavLink>
                    </div>

                    <div className="newsDetailsContainer">
                        <p className="typeNEDetails bigText">
                            {this.getTranslatedType(info?.Type)}
                        </p>

                        <h2 className="titleNEDetails h2Text">
                            {this.getTranslatedTitle(info)}
                        </h2>

                        <div className="labelWrapperNEDetails">
                            <p className="dateAuthorNEDetails smallText">
                                <b>{t('NewsEvents.Date')}: </b>{info.Date}
                            </p>
                            <p className="dateAuthorNEDetails smallText">
                                <b>{t('NewsEvents.Author')}: </b>{info.Author}
                            </p>
                        </div>

                        <ReactMarkdown className="textNEDetails">
                            {newsTranslation?.Body}
                        </ReactMarkdown>

                        {info.Link ?
                            <a 
                                href={info.Link} 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="linkNEDetails"
                            >
                                {info.Link}
                            </a>
                            : <></>}

                        <div className="galleryWrapperNE">
                            <Gallery img={gallery} />
                        </div>
                    </div>

                </div>


            </Body>
        );
    }
}

export default withTranslation(['translation'])(NewEventDetailsClass);