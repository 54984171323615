import React from 'react';
import { withTranslation } from 'react-i18next';

import Body from '../../templates/Body/Body';

import Bulgaria from '../../assets/images/contacts/bulgaria.svg';
import Croatia from '../../assets/images/contacts/croatia.svg';
import Czech from '../../assets/images/contacts/czech.svg';
import Finland from '../../assets/images/contacts/finland.svg';
import Germany from '../../assets/images/contacts/germany.svg';
import Italy from '../../assets/images/contacts/italy.svg';
import Portugal from '../../assets/images/contacts/portugal.svg';
import Romania from '../../assets/images/contacts/romania.svg';
import Spain from '../../assets/images/contacts/spain.svg';
import Lithuania from '../../assets/images/contacts/lithuania.svg';
import World from '../../assets/images/contacts/world.svg'
import Greece from '../../assets/images/contacts/greece.webp';
import Turkey from '../../assets/images/contacts/turkey.webp';

import './Contacts.css';

const ContactsList = [
    {
        id: 'portugal',
        imgSrc: Portugal,
        imgAlt: 'Portugal Logo',
        gmaps: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6225.939828470626!2d-9.15003437350696!3d38.71849971839438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd193379ae86c37f%3A0x60e5cf4e502a31c5!2sCinemateca%20Portuguesa%20Cinema%20Museum!5e0!3m2!1sen!2spt!4v1664367284384!5m2!1sen!2spt',
        orgs: [{
            organization: 'Cinemateca Portuguesa',
            representative: 'Vera Herold',
            address: 'R. Barata Salgueiro 39, 1269-059 Lisboa',
            contact: '+351 213 596 200',
            email: 'cinemateca@cinemateca.pt',
            link: 'http://www.cinemateca.pt/'
        },
        {
            organization: 'Os Filhos de Lumière',
            representative: 'Teresa Garcia',
            address: 'R. Gaivotas Nº2, 1200-202 Lisboa',
            contact: '+351 913480370',
            email: 'filhos.lumiere@gmail.com',
            link: 'http://osfilhosdelumiere.com/home/'
        }]
    },
    {
        id: 'spain',
        imgSrc: Spain,
        imgAlt: 'Spain Logo',
        gmaps: 'https://maps.google.com/maps?q=A+Bao+A+Qu,+Carrer+de+Lepant,+264,+08013+Barcelona,+Espanha&t=&z=13&ie=UTF8&iwloc=&output=embed',
        orgs: [{
            organization: 'Associació A Bao A Qu',
            representative: 'Núria Aidelman',
            address: 'Carrer de Lepant, 264, 08013 Barcelona',
            contact: '+34 93 285 31 81',
            email: 'abaoaqu@abaoaqu.org',
            link: 'https://www.abaoaqu.org/'
        }]
    },
    {
        id: 'bulgaria',
        imgSrc: Bulgaria,
        imgAlt: 'Bulgaria Logo',
        gmaps: 'https://maps.google.com/maps?q=Arte+Urbana+Collectif+/+Арте+Урбана+Колектив&t=&z=13&ie=UTF8&iwloc=&output=embed',
        orgs: [{
            organization: 'Association Arte Urbana Collectif',
            representative: 'Ralitsa Assenova',
            address: 'Галакси Трейс Център, Boulevard "Shipchenski Prohod" 18, офис 305, 1113 Geo Milev',
            contact: '+359 893 458 253',
            email: 'cinedbulgaria@gmail.com',
            link: 'https://www.arteurbanacollectif.com/'
        }]
    },
    {
        id: 'czech',
        imgSrc: Czech,
        imgAlt: 'Czech Logo',
        gmaps: 'https://maps.google.com/maps?q=Nábřeží+950/12,+Olomouc+779+00&t=&z=13&ie=UTF8&iwloc=&output=embed',
        orgs: [{
            organization: 'Asociace českých filmových klubů (AČFK)',
            representative: 'Eva Spilková',
            address: 'Olomouc, Czech Republic" 18, офис 305, 1113 Geo Milev',
            contact: '+420 604 274 58',
            email: 'eva.spilkova@acfk.cz',
            link: 'https://acfk.cz/'
        }]
    },
    {
        id: 'finland',
        imgSrc: Finland,
        imgAlt: 'Finland Logo',
        gmaps: 'https://maps.google.com/maps?q=Aleksis+Kiven+katu+17+A+15,+00150+Helsinki&t=&z=13&ie=UTF8&iwloc=&output=embed',
        orgs: [{
            organization: 'IhmeFilmi',
            representative: 'Mette Lagerstam',
            address: 'Fredrikinkatu 29 A 3, 00120 Helsinki',
            contact: '+045 6674 217',
            email: 'cined@ihmefilmi.fi',
            link: 'https://ihmefilmi.fi/'
        }]
    },
    {
        id: 'italy',
        imgSrc: Italy,
        imgAlt: 'Italy Logo',
        gmaps: 'https://maps.google.com/maps?q=Get+Coop.+A+R.l.&t=&z=13&ie=UTF8&iwloc=&output=embed',
        orgs: [{
            organization: 'GET Cooperativa sociale',
            representative: 'Maria Cascella',
            address: 'Via Capitaneo, 48, 70100 Bari',
            contact: '+39 340 127 58 10',
            email: 'cineditalia@gmail.com',
            link: 'http://www.getcinema.it/'
        }]
    },
    {
        id: 'romania',
        imgSrc: Romania,
        imgAlt: 'Romania Logo',
        gmaps: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2783.755509380792!2d21.229153315826466!3d45.75604722194235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x474567c64f8afaeb%3A0x920711d28a7e7f69!2sAsocia%C8%9Bia%20Cultural%C4%83%20CONTRASENS!5e0!3m2!1sen!2sus!4v1633072844060!5m2!1sen!2sus',
        orgs: [{
            organization: 'Cinema ARTA',
            representative: 'Sebestyen Monica',
            address: 'Str. Universității nr. 3 Cluj-Napoca',
            contact: '+40 724 906 751',
            email: 'contact@cinema-arta.ro',
            link: 'https://www.cinema-arta.ro/'
        },
        {
            organization: 'Contrasens',
            representative: 'Andreia Reisz',
            address: 'Proclamația de la Timișoara nr. 3, ap. 8A, Timișoara',
            contact: '+40 784 253 449',
            email: 'asociatia.contrasens@gmail.com',
            link: 'http://www.accontrasens.ro/ho/'
        }]
    },
    {
        id: 'germany',
        imgSrc: Germany,
        imgAlt: 'Germany Logo',
        gmaps: 'https://maps.google.com/maps?q=Schaumainkai+41,+60596+Frankfurt+am+Main,+Alemanha&t=&z=13&ie=UTF8&iwloc=&output=embed',
        orgs: [{
            organization: 'Deutsches Filminstitut and Filmmuseum',
            representative: 'Christine Kopf',
            address: 'Schaumainkai 41, 60596 Frankfurt',
            contact: '+49 69 961 220-308',
            email: 'cined@dff.film',
            link: 'https://www.dff.film/en/'
        },
        {
            organization: 'Deutsche Filmakademie e.V.',
            representative: 'Katja Hevemeyer',
            address: 'Köthener Straße 44, 10963 Berlin',
            contact: '+49 30 257 587 9-24',
            email: 'hevemeyer@deutsche-filmakademie.de',
            link: 'https://www.deutsche-filmakademie.de'
        }]
    },
    {
        id: 'croatia',
        imgSrc: Croatia,
        imgAlt: 'Croatia Logo',
        gmaps: 'https://maps.google.com/maps?q=Bosanska+ul.+10,+10000,+Zagreb,+Croácia&t=&z=13&ie=UTF8&iwloc=&output=embed',
        orgs: [{
            organization: 'Bacači Sjenki / Shadow Casters',
            representative: 'Ivana Šešlek',
            address: 'Bosanska 10, 10000 Zagreb, Croatia',
            contact: '+385 1 464 02 62 or +385 1 464 02 62',
            email: 'ivana.bacacisjenki@gmail.com',
            link: 'https://www.bacaci-sjenki.hr/en/'
        }]
    },
    {
        id: 'lithuania',
        imgSrc: Lithuania,
        imgAlt: 'Lithuania Logo',
        gmaps: 'https://maps.google.com/maps?q=A.+Goštauto+g.+2,+Vilnius 01104,+Lituânia&t=&z=13&ie=UTF8&iwloc=&output=embed',
        orgs: [{
            organization: 'Meno Avilys',
            representative: 'Gintė Žulytė',
            address: 'A. Gostauto str. 2, Vilnius 01104, Lithuania',
            contact: '+370 68050575',
            email: 'gzulyte@gmail.com',
            link: 'http://www.menoavilys.org/'
        }]
    },
    {
        id: 'greece',
        imgSrc: Greece,
        imgAlt: 'Greece Logo',
        gmaps: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3144.837284698822!2d23.7103230153236!3d37.98092647972251!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a1bcdee0d2c961%3A0x770105eb104df53c!2sThe%20Greek%20Film%20Archive%20Foundation!5e0!3m2!1sen!2spt!4v1669138463076!5m2!1sen!2spt',
        orgs: [{
            organization: 'Greek Film Archive',
            representative: 'Ioulia Mermigka',
            address: 'Iera odos 48 & Megalou Alexandrou 134-136 T.K. 104 35, Kerameikos, Athens, Greece',
            contact: '+30 21 0360 9695',
            email: 'contact@tainiothiki.gr',
            link: 'http://www.tainiothiki.gr/en/'
        }]
    },
    {
        id: 'turkey',
        imgSrc: Turkey,
        imgAlt: 'Turkey Logo',
        gmaps: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12091.645753895873!2d30.3321715!3d40.7419742!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc7996fa884f94c03!2sSakarya%20University!5e0!3m2!1sen!2spt!4v1669138717240!5m2!1sen!2spt',
        orgs: [{
            organization: 'Sakarya University',
            representative: 'Serhat Yetimova',
            address: 'Sakarya University Esentepe Campus 54050 Serdivan, Sakarya, Turkey',
            contact: '+90 (264) 295 54 54',
            email: 'serhaty@sakarya.edu.tr',
            link: 'https://sakarya.edu.tr/en/'
        }]
    }
];


class ContactsClass extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedOption: ''
        };
    }

    goRegister() {
        window.location = "/registration";
    }

    goFAQ() {
        window.location = "/frequently-asked-questions";
    }

    selectOption(opt) {
        this.setState({ selectedOption: opt })
    }

    render() {
        const { t } = this.props;
        const { selectedOption } = this.state;

        return (
            <Body
                bodyClass="content"
                title={`${t('NavBar.Contacts')} | CinEd`}
            >
                <div className="contactsPageContainer">
                    <h1 className="contactsTitle h1Text">
                        {t('ContactsPage.Title')}
                    </h1>

                    <h2 className="contactsSubTitle h3Text">
                        {t('ContactsPage.Subtitle')}
                    </h2>

                    <a
                        href="/registration"
                        className="contactsJoin bigText"
                    >
                        {t('ContactsPage.SignUpLink')}
                    </a>

                    <div className="countriesList">
                        {ContactsList.map((c) =>
                            <img
                                key={c.id}
                                className="columnCountries"
                                src={c.imgSrc}
                                alt={c.imgAlt}
                                onClick={() => this.selectOption(c.id)}
                                style={{ opacity: selectedOption === c.id ? 1 : 0.42 }}
                            />
                        )}
                    </div>

                    <div className="globeWrapper" onClick={this.goFAQ}>
                        <span className="contactsJoin bigText">
                            {t('ContactsPage.World')}
                        </span>

                        <img
                            className="globeImg"
                            src={World}
                            alt="World"
                        />
                    </div>

                    {ContactsList.map((c) =>
                        <div className="centerLayout" key={c.id}>
                            {selectedOption !== c.id ? <></> :
                                <div className="infoLayout">
                                    <div className="rectangle">
                                        {c.orgs.map((org, index) => <div className="infoContainer" key={index}>
                                            <p className="cInfo smallText">
                                                <b>{t('ContactsPage.Organization')}:</b> {org.organization}
                                            </p>

                                            <p className="cInfo smallText">
                                                <b>{t('ContactsPage.Representative')}:</b> {org.representative}
                                            </p>

                                            <p className="cInfo smallText">
                                                <b>{t('ContactsPage.Address')}:</b> {org.address}
                                            </p>

                                            <p className="cInfo smallText">
                                                <b>{t('ContactsPage.Contact')}:</b> {org.contact}
                                            </p>

                                            <p className="cInfo smallText">
                                                <b>Email:</b> {org.email}
                                            </p>

                                            <a href={org.link} target="_blank" rel="noopener noreferrer">
                                                <button className="btnWebsite">
                                                    {t('ContactsPage.VisitWebsite')}
                                                </button>
                                            </a>
                                        </div>)}
                                    </div>
                                    
                                    <div className="mapContainer">
                                        <iframe
                                            title="8"
                                            src={c.gmaps}
                                            frameBorder="0"
                                            allowFullScreen
                                        />
                                    </div>
                                </div>}
                        </div>)}
                </div>
            </Body>
        );

    }
}

export default withTranslation(['translation'])(ContactsClass);