import React from 'react';
import { useTranslation } from 'react-i18next';

const TechnicalDataColor = {
    'Color': 'Color',
    'Black and White': 'BlackWhite',
    'Black and White & Color': 'BlackWhiteColor'
};

const TechnicalDataType = {
    'Feature Film': 'FeatureFilm',
    'Short Film': 'ShortFilm'
};

export default function TechnicalData({ movie, technicaldata }) {
    const { t } = useTranslation();

    const getValue = (td, tname) => {
        const tdvalue = td[tname];
        let translatedvalue = '';

        switch (tname) {
            // in case technical data key is Color (as defined in Strapi backoffice)
            case 'Color':
                // map movie's technical data value using TechnicalDataColor
                let colorkey = TechnicalDataColor[tdvalue];
                // if color key is valid, retrieve i18next translation
                translatedvalue = colorkey ? t(`Movie.${colorkey}`) : td[tname];
                break;
            // in case technical data key is Type (as defined in Strapi backoffice)
            case 'Type':
                let typekey = TechnicalDataType[tdvalue];
                translatedvalue = typekey ? t(`Movie.${typekey}`) : td[tname];
                break;
            default:
                translatedvalue = td[tname];
        }

        return translatedvalue;
    }

    const td = movie?.TechnicalData;

    return (
        technicaldata.map((tname) =>
            <div key={tname}>
                <div className="modalSection">
                    <div className="widthLabel">
                        <p className="modalSectionLabel normalText">
                            {t(`Movie.${tname}`)}:
                        </p>
                    </div>

                    <div className="widthInfo">
                        <p className="modalSectionInfo normalText">
                            {getValue(td, tname)}
                        </p>
                    </div>
                </div>
                <div className="separatorModal" />
            </div>
        )
    )
}