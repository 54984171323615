import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Loading from '../../components/Loading/Loading';
import DateInputStyles from '../TextInput/DateInputStyles';
import { Roles } from '../../utils/utils';

import VisibilityIcon from '@mui/icons-material/Visibility';
const eye = <VisibilityIcon fontSize="medium" />;

const ProfileForm = (props) => {
    const {
        title,
        desc1,
        desc2,
        name,
        username,
        email,
        telephone,
        birthDate,
        country,
        city,
        role,
        institution,
        school,
        question,
        answer,
        password,
        myProfile,
        isTeacher,
        passwordShown,
        loading,
        disabled,
        errorMessage,
        successMessage,
        handleFormSubmit,
        onChange,
        onDateChange,
        selectCountry,
        selectCity,
        togglePasswordVisiblity,
    } = props;
    const { t } = useTranslation();
    const passwordRequired = !myProfile;

    return (
        <div className="signInForm authForm migration">
            <div className="signInFormWrapper">
                {loading ? (
                    <Loading />
                ) : (
                    <div className="signInStudentTeacher">
                        <div className="migrationHeader">
                            <h2 className="migrationTitle h2Text">{title}</h2>

                            {!myProfile ? (
                                <p className="migrationDescription normalText">
                                    {desc1}
                                    <br />
                                    {isTeacher ? desc2 : <></>}
                                </p>
                            ) : (
                                <></>
                            )}
                        </div>

                        <form onSubmit={handleFormSubmit}>
                            <div className="registerInputsWrapper migrationForm">
                                <div className="authColumn">
                                    <input
                                        className="inputRegister"
                                        type="text"
                                        name="name"
                                        placeholder={t('SignInUp.RegisterName')}
                                        value={name}
                                        onChange={onChange}
                                        required={true}
                                    />
                                    <div className="fieldsDivision" />

                                    <input
                                        className="inputRegister"
                                        type="text"
                                        name="username"
                                        placeholder={t('SignInUp.RegisterUsername')}
                                        value={username}
                                        onChange={onChange}
                                        required={true}
                                    />
                                    <div className="fieldsDivision" />

                                    <input
                                        className="inputRegister"
                                        type="email"
                                        name="email"
                                        placeholder={t('SignInUp.RegisterEmail')}
                                        value={email}
                                        onChange={onChange}
                                        required={true}
                                    />
                                    <div className="fieldsDivision" />

                                    {isTeacher ? (
                                        <>
                                            <input
                                                className="inputRegister"
                                                type="tel"
                                                name="telephone"
                                                placeholder={t('SignInUp.RegisterTelephone')}
                                                value={telephone}
                                                onChange={onChange}
                                                required={true}
                                            />
                                            <div className="fieldsDivision" />

                                            <DatePicker
                                                label={t('SignInUp.RegisterBirthDate')}
                                                value={birthDate}
                                                onChange={onDateChange}
                                                maxDate={moment()}
                                                slotProps={{
                                                    textField: {
                                                        variant: 'standard',
                                                        fullWidth: true,
                                                        required: true,
                                                        sx: DateInputStyles,
                                                    },
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <></>
                                    )}

                                    <CountryDropdown
                                        className="dropdownRegister"
                                        value={country}
                                        required={true}
                                        onChange={(val) => selectCountry(val)}
                                        defaultOptionLabel={t('SignInUp.SelectCountry')}
                                    />
                                    <div className="fieldsDivision" />

                                    <RegionDropdown
                                        className="dropdownRegister"
                                        country={country}
                                        required={true}
                                        value={city}
                                        onChange={(val) => selectCity(val)}
                                        defaultOptionLabel={t('SignInUp.SelectRegion')}
                                    />
                                    <div className="fieldsDivision" />
                                </div>

                                <div className="authColumn">
                                    {isTeacher ? (
                                        <>
                                            <select
                                                className="dropdownRegister"
                                                name="role"
                                                value={role}
                                                onChange={onChange}
                                            >
                                                <option value={Roles.teacher}>
                                                    {t('SignInUp.TeacherEducatorTutor')}
                                                </option>
                                                <option value={Roles.training}>{t('SignInUp.TTraining')}</option>
                                                <option value={Roles.professional}>{t('SignInUp.Professional')}</option>
                                                <option value={Roles.researcher}>
                                                    {t('SignInUp.ResearcherUniversity')}
                                                </option>
                                                <option value={Roles.other}>{t('SignInUp.Other')}</option>
                                            </select>
                                            <div className="fieldsDivision" />

                                            <select
                                                className="dropdownRegister"
                                                name="institution"
                                                value={institution}
                                                onChange={onChange}
                                            >
                                                <option value="Cultural Structure or Association">
                                                    {t('SignInUp.CulturalAssociation')}
                                                </option>
                                                <option value="Internal Association">
                                                    {t('SignInUp.InternalAssociation')}
                                                </option>
                                                <option value="University">{t('SignInUp.University')}</option>
                                                <option value="School">{t('SignInUp.School')}</option>
                                                <option value="Cinematheque">{t('SignInUp.Cinematheque')}</option>
                                                <option value="Cinema">{t('SignInUp.Cinema')}</option>
                                                <option value="Other">{t('SignInUp.Other')}</option>
                                            </select>
                                            <div className="fieldsDivision" />
                                        </>
                                    ) : (
                                        <>
                                            <input
                                                className="inputRegister"
                                                type="text"
                                                name="school"
                                                placeholder={t('SignInUp.RegisterSchool')}
                                                value={school}
                                                onChange={onChange}
                                                required={true}
                                            />
                                            <div className="fieldsDivision" />
                                        </>
                                    )}

                                    <input
                                        className="inputRegister"
                                        type="text"
                                        name="question"
                                        placeholder={t('SignInUp.SecurityQuestion')}
                                        value={question}
                                        onChange={onChange}
                                        required={true}
                                    />
                                    <div className="fieldsDivision" />

                                    <input
                                        className="inputRegister"
                                        type="text"
                                        name="answer"
                                        placeholder={t('SignInUp.SecurityAnswer')}
                                        value={answer}
                                        onChange={onChange}
                                        required={true}
                                    />
                                    <div className="fieldsDivision" />

                                    <div className="passwordRegisterWrappper">
                                        <input
                                            className="inputRegister"
                                            type={passwordShown ? 'text' : 'password'}
                                            name="password"
                                            value={password}
                                            placeholder={t('SignInUp.RegisterPassword')}
                                            onChange={onChange}
                                            required={passwordRequired}
                                        />
                                        <i className="eyeSignUp" onClick={togglePasswordVisiblity}>
                                            {eye}
                                        </i>
                                        <div className="fieldsDivision"></div>
                                    </div>
                                </div>
                            </div>

                            {errorMessage ? <p className="error">{errorMessage}</p> : <></>}

                            {successMessage ? <p className="success">{successMessage}</p> : <></>}

                            <button type="submit" className="submitRegistration detailsText" disabled={disabled}>
                                {t('Player.UpdateAccount')}
                            </button>
                        </form>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfileForm;
