export function openDeepLink(url, options) {
    let timeout;
    let interval;
    let visible = 'visible';

    const handleOpen = () => {
        window.removeEventListener('visibilitychange', () => true);
        options?.onOpen?.();
    };
    const handleResponse = () => {
        if (visible === 'visible') return options?.onFail?.();
        clearInterval(interval);
        handleOpen();
    };

    try {
        window.addEventListener('visibilitychange', (e) => (visible = e.target?.visibilityState));
        timeout = setTimeout(handleResponse, options?.waitTime || 5000);

        interval = setInterval(() => {
            if (visible === 'hidden') {
                clearTimeout(timeout);
                handleResponse();
            }
        }, options?.waitTime || 5000);

        window.location.href = url;
    } catch (error) {
        options?.onFail?.();
    }
}
