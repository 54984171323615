import React from 'react';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

import Body from '../../templates/Body/Body';
import Loading from '../../components/Loading/Loading';
import ResourcesItem from './ResourcesItem';
import { getTransversalResources } from '../../api/transversal-resources';
import { Translations } from '../../utils/utils';
import { BASE_URL } from '../../utils/globals';

import Dossier1Cover from '../../assets/images/dossiers/dossier-1.jpg';
import Dossier2Cover from '../../assets/images/dossiers/dossier-2.jpg';
import Dossier3ACover from '../../assets/images/dossiers/dossier-3a.jpg';
import Dossier3BCover from '../../assets/images/dossiers/dossier-3b.jpg';
import Dossier4Cover from '../../assets/images/dossiers/dossier-4.jpg';

import './TeachingResources.css';

const OriginalPDFTranslationsKey = 'OriginalPDFTranslations';
const PrintablePDFTranslationsKey = 'PrintablePDFTranslations';

class TeachingResources extends React.Component {

    constructor() {
        super();

        this.state = {
            teachingResources: [],
            loading: true
        };
    }

    async componentDidMount() {
        let teachingResources;
        try {
            teachingResources = await getTransversalResources();
        } catch (error) {
            console.error('An error occurred: ', error);
            return;
        }

        this.setState({
            loading: false,
            teachingResources: teachingResources
        });
    }

    getDossierFile(name, original, lang, teachingResources) {
        const item = teachingResources.find(r => r.name === name);

        let language = Translations[lang] ? Translations[lang] : '';
        if (lang === 'DE') { language = 'German'; }
        else if (language) {
            language = language.charAt(0).toUpperCase() + language.slice(1);
        }

        let file;
        let originalkey = original ? OriginalPDFTranslationsKey : PrintablePDFTranslationsKey;
        const translations = item && item[originalkey] ? item[originalkey] : item;
        if (translations[language]) {
            file = translations[language].url;
        } else {
            const englishfile = translations?.English?.url
            file = englishfile ? englishfile : item?.English?.url;
        }

        return `${BASE_URL}${file}`;
    }

    render() {
        const { t } = this.props;
        const { loading, teachingResources } = this.state;

        if (loading) {
            return (
                <Body
                    bodyClass="content resourcesPage"
                    title={`${t('NavBar.TeachingResources')} | CinEd`}
                >
                    <Loading />
                </Body>
            );
        }

        return (
            <Body
                bodyClass="content resourcesPage"
                title={`${t('NavBar.TeachingResources')} | CinEd`}
            >
                <div className="container pageContainer">
                    <h1 className="contactsTitle h1Text">
                        {t('TeachingResources.Title')}
                    </h1>

                    <h2 className="contactsSubTitle h3Text">
                        {t('TeachingResources.SubTitle')}
                    </h2>

                    <h3 className="resourcesH3 h3Text">
                        {t('TeachingResources.Q1')}
                    </h3>

                    <p className="resourcesText normalText">
                        {t('TeachingResources.A1Part1')}
                    </p>

                    <p className="resourcesText normalText">
                        {t('TeachingResources.A1Part2')}
                    </p>

                    <h3 className="resourcesH3 h3Text">
                        {t('TeachingResources.Q2')}
                    </h3>

                    <p className="resourcesText normalText">
                        {t('TeachingResources.A2')}
                    </p>

                    <h3 className="resourcesH3 h3Text">
                        {t('TeachingResources.Q3')}
                    </h3>

                    <p className="resourcesText normalText">
                        {t('TeachingResources.A3')}
                    </p>

                    {i18next.language === 'CZ' ? <p className="resourcesText normalText">
                        {t('TeachingResources.A3Part2')}
                    </p> : <></>}

                    <h3 className="resourcesH3 h3Text">
                        {t('TeachingResources.Q4')}
                    </h3>

                    <div className="resourceItemsContainer">
                        <ResourcesItem
                            alignRight={false}
                            title={t('TeachingResources.T1')}
                            imgSrc={Dossier1Cover}
                            imgAlt="Civic Inclusion and Gender Equality Dossier Cover"
                            dossierLink={this.getDossierFile('Dossier 1', true, i18next.language, teachingResources)}
                            printableDossier={this.getDossierFile('Dossier 1', false, i18next.language, teachingResources)}
                            bkgColor="#595b98"
                        >
                            {t('TeachingResources.T1Desc')}
                        </ResourcesItem>

                        <ResourcesItem
                            alignRight={false}
                            title={t('TeachingResources.T2')}
                            imgSrc={Dossier2Cover}
                            imgAlt="Geography and Landscapes Dossier Cover"
                            dossierLink={this.getDossierFile('Dossier 2', true, i18next.language, teachingResources)}
                            printableDossier={this.getDossierFile('Dossier 2', false, i18next.language, teachingResources)}
                            bkgColor="#2e849d"
                        >
                            {t('TeachingResources.T2Desc')}
                        </ResourcesItem>

                        <div>
                            <ResourcesItem
                                alignRight={false}
                                title={t('TeachingResources.T3')}
                                subtitle={t('TeachingResources.T3A')}
                                imgSrc={Dossier3ACover}
                                imgAlt="Transmission - Intergenerational Relations Dossier Cover"
                                dossierLink={this.getDossierFile('Dossier 3A', true, i18next.language, teachingResources)}
                                printableDossier={this.getDossierFile('Dossier 3A', false, i18next.language, teachingResources)}
                                bkgColor="#bd70a6"
                            >
                                {t('TeachingResources.T3ADesc')}
                            </ResourcesItem>

                            <ResourcesItem
                                alignRight={false}
                                subtitle={t('TeachingResources.T3B')}
                                imgSrc={Dossier3BCover}
                                imgAlt="Transmission - Cinema as Heritage Dossier Cover"
                                dossierLink={this.getDossierFile('Dossier 3B', true, i18next.language, teachingResources)}
                                printableDossier={this.getDossierFile('Dossier 3B', false, i18next.language, teachingResources)}
                                bkgColor="#bd70a6"
                            >
                                {t('TeachingResources.T3BDesc')}
                            </ResourcesItem>
                        </div>

                        <ResourcesItem
                            alignRight={false}
                            title={t('TeachingResources.T4')}
                            imgSrc={Dossier4Cover}
                            imgAlt="Environment, Economics Dossier Cover"
                            dossierLink={this.getDossierFile('Dossier 4', true, i18next.language, teachingResources)}
                            printableDossier={this.getDossierFile('Dossier 4', false, i18next.language, teachingResources)}
                            bkgColor="#2d7b63"
                        >
                            {t('TeachingResources.T4Desc')}
                        </ResourcesItem>
                    </div>

                    <div className="closingText">
                        <p className="resourcesText normalText">
                            {t('TeachingResources.Closing')}&nbsp;
                            <a href="mailto:vera.herold@cinemateca.pt,katia.cordas@cinemateca.pt" target="_blank" rel="noopener noreferrer">
                                {t('TeachingResources.SendEmail')}
                            </a>
                        </p>
                    </div>

                </div>
            </Body>
        )
    }
}

export default withTranslation(['translation'])(TeachingResources);