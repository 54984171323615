import React, { Component } from 'react';
import './loading.css';

export default class Loading extends Component {
    render() {
        return (
            <div className="loading">
                <div className="item"></div>
                <div className="item"></div>
                <div className="item"></div>
                <div className="item"></div>
                <div className="item"></div>
            </div>
        );
    }
}