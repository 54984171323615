import React from 'react';

export default function Arrow(props) {
    const { className, imgSrc, imgAlt, style, onClick } = props;
    return (
        <img
            className={className}
            src={imgSrc}
            alt={imgAlt}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}