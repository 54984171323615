import React from 'react';

export default function ContactsIcon({ fill }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="97" height="97" viewBox="0 0 97 97" fill="none">
      <g clipPath="url(#clip0_471_550)">
        <path
          d="M67.25 29.58C65.31 27.64 61.92 24.25 55.61 24.25H43.49C31.32 24.25 21.49 15.68 19.58 4.13C19.18 1.76 17.18 0 14.78 0C11.81 0 9.50998 2.65 9.98998 5.58C12.1 18.5 21.45 28.67 33.79 32.5V92.16C33.79 94.84 35.96 97.01 38.64 97.01C41.32 97.01 43.49 94.84 43.49 92.16V72.76C43.49 70.08 45.66 67.91 48.34 67.91C51.02 67.91 53.19 70.08 53.19 72.76V92.16C53.19 94.84 55.36 97.01 58.04 97.01C60.72 97.01 62.89 94.84 62.89 92.16V39.28L78.87 54.86C80.76 56.71 83.79 56.69 85.66 54.82C87.55 52.93 87.55 49.87 85.66 47.99L67.25 29.58ZM48.34 0C53.67 0 58.04 4.36 58.04 9.7C58.04 15.04 53.67 19.4 48.34 19.4C43.01 19.4 38.64 15.04 38.64 9.7C38.64 4.36 43 0 48.34 0Z"
          fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0_471_550">
          <rect width="97" height="97" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}