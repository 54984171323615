import React from 'react';
import { NavLink } from 'react-router-dom';

import NavBarComponent from '../../templates/NavBar/NavBar';
import MetaTags from '../../components/MetaTags/MetaTags';

import background from '../../assets/images/noAccount.svg';
import './NoAccount.css';

class NoAccount extends React.Component {
    render() {
        return (
            <div className="App" style={{ backgroundImage: `url(${background})` }}>
                <MetaTags title={`No Account | CinEd`} />
                <NavBarComponent /> 

                <div className="noAccountContent">
                    <p className="noAccountTitle">It looks like you are not signed in!</p>

                    <div className="noAccountButtonsWrapper">
                        <NavLink to="/login"><button className="noAccountButtonsS">Sign In</button></NavLink>
                        <NavLink to="/registration"><button className="noAccountButtonsR">Create New Account</button></NavLink>
                    </div>
                </div>

            </div>
        );

    }
}

export default NoAccount;
